import React, {useContext, useEffect, useState} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Button1 from '../../atoms/button/button1';
import { DataContext } from '../../../config/context';

const editorConfiguration = {
    toolbar:{
        items: [
            'undo', 'redo',
            '|', 'heading',
            '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
            '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
            '-', // break point
            '|', 'alignment',
            'link', 'uploadImage', 'blockQuote', 'codeBlock',
            '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
        ],
        shouldNotGroupWhenFull: true
    }
    
};

const AddOpportunity = (props) => {
    const[opportunity, setOpportunity] = useContext(DataContext)
    const [disabled,setDisabled] = useState(false)
    // if(props?.data[0]?.isDisabled) setDisabled(true) 
    return (
        <div className='p-3 pt-5'>
            <CKEditor
                editor={ Editor }
                config={ editorConfiguration }
                data={opportunity.opportunityContext}
                disabled={props.isDisabled}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData()
                    setOpportunity(e => ({
                        ...e, opportunityContext: data
                    }))
                } }
                onBlur={ ( event, editor ) => {
                    // console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    // console.log( 'Focus.', editor );
                } }
            />
        </div>
    ) 
}

export default AddOpportunity;
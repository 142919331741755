import React, {useEffect, useState, useContext} from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight, Camera } from 'lucide-react'
import Input1 from '../../atoms/input/Input1';
import Button1 from '../../atoms/button/button1';
import {storage} from '../../../config/firebase'
import {getDownloadURL, ref as refFirebase, uploadBytes, getStorage, deleteObject} from 'firebase/storage'
import { DataContext } from '../../../config/context';
import {v4} from 'uuid'
import Image1 from '../../atoms/image/image1';
import TextArea1 from '../../atoms/input/textarea1';

const ProjectPicture = (props) => {
    const[dataContext, setDataContext] = useContext(DataContext)
    const[imageUpload, setImageUpload] = useState(null)

    function getPathStorageFromUrl(url){
        const baseUrl = "https://firebasestorage.googleapis.com/v0/b/pppbookbappenas-1b81a.appspot.com/o/";
        let imagePath = url.replace(baseUrl,"");
        const indexOfEndPath = imagePath.indexOf("?");
        imagePath = imagePath.substring(0,indexOfEndPath);
        imagePath = decodeURIComponent(imagePath);
        // imagePath = imagePath.replace("%2F","/");
        // console.log(imagePath)
        return imagePath;
    }    

    const onUpload = async (e) => {
        e.preventDefault()
        try {
            const data = await uploadImage()
            console.log(data)
            console.log(imageUpload.name)
            setDataContext(e => ({
                ...e, projectPictureContext: data
            }))
        } catch (error) {
            console.log(error);
        }
    }

    const onDelete = async (e) => {
        e.preventDefault()
        await deleteImage()
    }
    
    const uploadImage = async () => {
        if(imageUpload == null) return
        const imageRef = refFirebase(storage, `projectPicture/${imageUpload.name}`)
        await uploadBytes(imageRef, imageUpload)
        return await getDownloadURL(imageRef)
    }

    const deleteImage = async () => {
        const imageRef = refFirebase(storage, getPathStorageFromUrl(dataContext.projectPictureContext))
        console.log(imageRef)
        await deleteObject(imageRef)
        .then(() => {
            console.log("Success Deleted Image")
            setDataContext(e => ({
                ...e, projectPictureContext: ''
            }))
            setImageUpload(null)
        }).catch((error) => {
            console.log(error)
        })
    }

    return ( 
        <div className='m-auto'>
            <form>
                <div className="space-y-12">
                    <div className="sm:col-span-2 mx-2 mb-5">
                        <label htmlFor="documentProjectStatus" className="block text-sm font-medium leading-6 text-gray-900">
                            Description
                        </label>
                        <div className="mt-2">
                            <TextArea1 
                            value={dataContext.descriptionProjectPicture}
                            onChangeText={(val) =>{
                                const data = val.target.value
                                setDataContext(e => ({
                                    ...e, descriptionProjectPicture: data
                                }))
                            }}
                            placeholder={"Description Project Picture"}></TextArea1>
                        </div>
                    </div>
                    <div className="col-span-full flex flex-col items-center justify-center cursor-pointer">
                        {imageUpload == null && dataContext.projectPictureContext == '' || dataContext.projectPictureProfileContext == undefined
                        ? <div className="h-[240px] w-[240px] mt-2 flex items-center justify-center gap-x-3 bg-gray-200 rounded-full">
                            <input onChange={(event) => {
                                setImageUpload(event.target.files[0])
                            }} className="absolute opacity-0 h-[240px] w-[240px] cursor-pointer" name="file" type="file"/>
                            <Camera className="h-[120px] w-[120px] text-gray-900" aria-hidden="true" />
                        </div>
                        :<img src={dataContext.projectPictureContext} alt='Image already input, but not yet upload' className='italic text-gray-400'/>
                        }
                        {props.isDisabled == true?<></>:
                            <div>
                                <button onClick={onUpload} className="block mt-8 text-md text-center font-sm leading-6 text-blue-500">
                                    Upload Picture
                                </button>
                                {imageUpload == null && dataContext.projectPictureContext == '' || dataContext.projectPictureContext == undefined
                                ?<></>
                                :
                                <button onClick={onDelete} className="block mt-8 text-md text-center font-sm leading-6 text-red-500">
                                    Delete Picture
                                </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ProjectPicture;
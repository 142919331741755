import React, {useEffect, useState, useContext, createContext} from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from '../../navigation/navbar';
import SideBar from '../../navigation/sidebar';
import AddStatus from '../../components/templates/form/datamaster/status/status';
import AddGcam from '../../components/templates/form/datamaster/gcam/gcam';
import AddYear from '../../components/templates/form/datamaster/year/year';
import AddTypeOfPPP from '../../components/templates/form/datamaster/typeOfPPP/typeOfPPP';
import DataStatusTable from '../../components/templates/table/datamaster/statusTable';
import DataGcamTable from '../../components/templates/table/datamaster/gcamTable';
import DataTypeOfPPPTable from '../../components/templates/table/datamaster/typeOfPPPTable';
import DataYearTable from '../../components/templates/table/datamaster/yearTable';
import Dropdown1 from '../../components/atoms/dropdown/dropdown1'
import AddSector from '../../components/templates/form/datamaster/sector/sector';
import DataSectorTable from '../../components/templates/table/datamaster/sectorTable';
import AddSubsector from '../../components/templates/form/datamaster/subsector/subsector';
import DataSubsectorTable from '../../components/templates/table/datamaster/subsectorTable';
import AddRoi from '../../components/templates/form/datamaster/roi/roi';
import DataRoiTable from '../../components/templates/table/datamaster/roiTable';
import AddProjectStatus from '../../components/templates/form/datamaster/projectStatus/projecttStatus';
import DataProjectStatusTable from '../../components/templates/table/datamaster/projectStatus';
import AddSubBab from '../../components/templates/form/datamaster/subBab/subBab';
import DataSubBabTable from '../../components/templates/table/datamaster/subBabTable';

const DataMaster =() => {
    const [menu, setMenu] = useState("")
    const [sidebar, setSidebar] = useState([])
    const [index, setIndex] = useState(0)

    const Components = [
        {
            input: <AddStatus/>,
            table: <DataStatusTable/>
        },
        {
            input: <AddGcam/>,
            table: <DataGcamTable/>
        },
        {
            input: <AddTypeOfPPP/>,
            table: <DataTypeOfPPPTable/>
        },
        {
            input: <AddYear/>,
            table: <DataYearTable/>
        },
        {
            input: <AddSector/>,
            table: <DataSectorTable/>
        },
        {
            input: <AddSubsector/>,
            table: <DataSubsectorTable/>
        },
        {
            input: <AddRoi/>,
            table: <DataRoiTable/>
        },
        {
            input: <AddProjectStatus/>,
            table: <DataProjectStatusTable/>
        },
        {
            input: <AddSubBab/>,
            table: <DataSubBabTable/>
        },
    ]

    const Options = [
        {value:0,label:"Status"},
        {value:1,label:"GCAM"},
        {value:2,label:"Type Of PPP"},
        {value:3,label:"Year"},
        {value:4,label:"Sector"},
        {value:5,label:"Sub-Sector"},
        {value:6,label:"Return of Invesment"},
        {value:7,label:"Project Status"},
        {value:8,label:"Sub Bab"},
    ]

    return(
        <div className='flex bg-gray-200'>
                <div>
                    <SideBar/>
                </div>
                <div className='w-full'>
                    <Navbar/>
                        <div className='m-8 p-4 bg-white'>
                        <div className="sm:col-span-1">
                            <label htmlFor="options" className="block text-sm font-medium leading-6 text-gray-900">
                                Pilih Master Data
                            </label>
                            <div className="mt-2" >
                                <Dropdown1 options={Options} onChange={(val)=>{setIndex(val.target.value)}} value={index} isDisabled={false}/>
                            </div>
                        </div>
                        {Components[index].input}
                        <div className='mt-4'>
                            {Components[index].table}
                        </div>
                    </div>    
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                    </div>
            </div>
        </div>
    )
}

export default DataMaster;
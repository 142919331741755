import React, {useEffect, useState, useContext, createContext} from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataContext } from '../config/context';
import Navbar from '../navigation/navbar';
import SideBar from '../navigation/sidebar';
import CompareForm from '../components/templates/form/compareForm';

const CompareDataPage =() => {
    const [menu, setMenu] = useContext(DataContext)
    const [sidebar, setSidebar] = useState([])
    return(
        <div className='flex bg-gray-200'>
            <div>
                <SideBar/>
            </div>
            <div className='w-full'>
                <Navbar/>
                <div className='flex flex-row m-8 p-4 bg-white'>
                    <CompareForm/>
                    {/* <CompareForm/> */}
                </div>
            </div>
        </div>
    )
}

export default CompareDataPage;
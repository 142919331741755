import React, {useContext, useEffect, useState} from 'react';
import Input1 from '../../../../atoms/input/Input1';
import axios from "axios";
import Swal from 'sweetalert2'
import { myApi } from '../../../../../config/service/api';
import Button1 from '../../../../atoms/button/button1';
const AddTypeOfPPP = () => {
    const[typeOfPPP, setTypeOfPPP] = useState("")
    const [Loading, setLoading] = useState(false);
    const sendData = async (event) => {
        // console.log("abcd")
        Swal.fire({
            title: "Do you want to save the changes?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
            }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                try {
                    await axios.post(`${myApi.URLPROD}/typeofppp/createTypeOfPPP`, {typeOfPPPName: typeOfPPP,typeOfPPPDeleted:"no"}).then((res) => {
                        console.log(res.status)
                        if (res.status == 200) {
                            Swal.fire({
                                title: 'Saved',
                                text: 'Your work has been saved!',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        })
                    } else {
                        Swal.fire({
                            title: 'Failed',
                            text: "Changes are not saved",
                            icon: 'error',
                            confirmButtonText: 'OK',
                        })
                    }
                    console.log(res)
                    window.location.reload()
                    })
                    } catch (error) {
                    Swal.fire({
                        title: 'Failed',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                    }
                    setLoading(false)
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
            });
    }
    return ( 
        <div className='m-auto '>
        <form>
            <div className="space-y-3">
                <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 gap-4"
                    >
                     <div className="sm:col-span-1 mx-2">
                            <label htmlFor="documentProjectTypeOfPPP" className="block text-sm font-medium leading-6 text-gray-900">
                                TypeOfPPP
                            </label>
                            <div className="mt-2">
                                <Input1 name="year" value={typeOfPPP} onChangeText={(val) => setTypeOfPPP(val.target.value)} typeInput="text" placeholder="TypeOfPPP"></Input1>
                            </div>
                            <div className="mt-2">
                                <Button1 text="Add" type="button" onPress={()=>{sendData()}}></Button1>
                            </div>
                        </div>
                    
                </div>
                </div>
            </div>
        </form>
    </div>
    ) 
}

export default AddTypeOfPPP;
import React from 'react';

const Checkbox1 = (props) => {
    return (
        <div class="mr-5 ml-5 mt-2 mb-2"><div className="form-group">
            <label className="inline-flex items-center">
                <input
                    type="checkbox"
                    name={props.name}
                    checked={props.dataChecked}
                    onChange={props.setDataChecked}
                    disabled={props.isDisabled}
                    className="form-checkbox h-5 w-5 text-gray-600"
                />
                <span className="ml-2 text-gray-700">{props.label}</span>
            </label>
        </div>
        </div>

    );
}
export default Checkbox1



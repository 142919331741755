import React, {useEffect, useState} from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Input1 from '../../atoms/input/Input1';
import Button1 from '../../atoms/button/button1';

const AddProjectDigestPageSecond = (props) => {
  const [projectStatus, setProjectStatus] = useState('');
  const [OBC, setOBC] = useState('');
  const [FBC, setFBC] = useState('');
  const [preQualification, setPreQualification] = useState('');
  const [requestForProposal, setRequestForProposal] = useState('');
  const [aggrementSigning, setAggrementSigning] = useState('');
  const [bidAward, setBidAward] = useState('');
  const [financialClose, setFinancialClose] = useState('');
  const [construction, setConstruction] = useState('');

    return ( 
        <div className='m-auto'>
            <form>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                    <div className="
                        mt-10
                        grid grid-flow-col grid-cols-4 gap-4 
                        sm:grid-flow-col sm:grid-rows-4"
                        >
                        {props.data.map((item)=>{
                            return <div className={item.class}>
                                <label htmlFor="documentProjectStatus" className="block text-sm font-medium leading-6 text-gray-900">
                                    {item.placeholder}
                                </label>
                                <div className="mt-2">
                                    <Input1 name={item.name} value={item.name} onChangeText={(val) => item.onchange(val.target.value)} typeInput="text" placeholder={item.placeholder} isDisabled={item.isDisabled}></Input1>
                                </div>
                            </div>
                        })}
                    </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddProjectDigestPageSecond;
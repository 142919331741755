// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAbuAdEag4TUcw7YMSd-HBqPwTs7qloaxE",
  authDomain: "pppbookbappenas-1b81a.firebaseapp.com",
  projectId: "pppbookbappenas-1b81a",
  storageBucket: "pppbookbappenas-1b81a.appspot.com",
  messagingSenderId: "1086093642405",
  appId: "1:1086093642405:web:fbe57cfb215127d2395b4c",
  measurementId: "G-V5EGGC6Y3V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app)
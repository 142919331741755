import React, { useEffect, useState, useContext, createContext } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataContext } from '../../config/context';
import Navbar from '../../navigation/navbar';
import SideBar from '../../navigation/sidebar';
import UserTable from '../../components/templates/table/usermanagement/userManagementTable';

const UserManagementPage = () => {
    const [menu, setMenu] = useContext(DataContext)
    const [sidebar, setSidebar] = useState([])
    useEffect(() => {
    }, [])
    return (
        <div className='flex bg-gray-200'>
            <div>
                <SideBar />
            </div>
            <div className='w-full'>
                <Navbar />
                <div className='m-8 p-4 bg-white'>
                    <UserTable />
                </div>
            </div>
        </div>
    )
}

export default UserManagementPage;
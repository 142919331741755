import React, {useEffect, useState, useContext, createContext} from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataContext } from '../config/context';
import Navbar from '../navigation/navbar';
import SideBar from '../navigation/sidebar';
import ProjectCostStructure from '../components/templates/form/projectCostStructure';
import ProjectCostStructureNew from '../components/templates/form/projectCostStructureNew';
import TechnicalSpecification from '../components/templates/form/technicalSpecification';
import ProjectForm from '../components/templates/form/projectForm';
import IndicativeProjectScheduleForm from '../components/templates/form/indicativeProjectSchedule';
import AddProjectDigestPageSecond from '../components/templates/form/addProjectDigest';
import ContactInformation from '../components/templates/form/contactInformation';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Button1 from '../../src/components/atoms/button/button1';
import Swal from 'sweetalert2'
import { myApi } from '../../src/config/service/api';
import { NavLink } from "react-router-dom";
import AddOpportunity from '../components/templates/form/addOpportunity';
import AddBusinessEntityScopeOfWork from '../components/templates/form/addBusinessEntityScopeOfWork';
import AddEnvironmentImpact from '../components/templates/form/addEnvImpactAssesment';
import AddGovernmentSupport from '../components/templates/form/addGovernmentSupport';
import AddLandAcquisition from '../components/templates/form/addLandAcquisition';
import ProjectPictureProfile from '../components/templates/form/projectPictureProfile';
import IndicativeProjectStructure from '../components/templates/form/indicativeProjectStructure';
import ProjectPicture from '../components/templates/form/projectPicture';

export const OpportunityContext = createContext();

const AddProjectDigestPage =() => {
    // const [menu, setMenu] = useContext(DataContext)
    const [sidebar, setSidebar] = useState([])

    const [Loading, setLoading] = useState('');
    const [role, setRole] = useState('');
    const [projectYear, setProjectYear] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectStatus, setProjectStatus] = useState('');
    const [location, setLocation] = useState('');
    const [governmentContractingAgency, setGovernmentContractingAgency] = useState('');
    const [typeofPPP, setTypeofPPP] = useState('');
    const [returnOfInvesment, setReturnOfInvesment] = useState('');
    const [sector, setSector] = useState('');
    const [subSector, setSubSector] = useState('');
    const [description, setDescription] = useState('');
    const [estimatedProjectValueCap, setEstimatedProjectValueCap] = useState('');
    const [estimatedProjectValueOps, setEstimatedProjectValueOps] = useState('');
    const [equityIrr, setEquityIrr] = useState('');
    const [firr, setFirr] = useState('');
    const [npv, setNpv] = useState('');
    const [estimatedConcessionPeriod, setEstimatedConcessionPeriod] = useState('');
    // -----------------------------
    const [projectStatus2, setProjectStatus2] = useState('');
    const [OBC, setOBC] = useState('');
    const [FBC, setFBC] = useState('');
    const [preQualification, setPreQualification] = useState('');
    const [requestForProposal, setRequestForProposal] = useState('');
    const [aggrementSigning, setAggrementSigning] = useState('');
    const [bidAward, setBidAward] = useState('');
    const [financialClose, setFinancialClose] = useState('');
    const [construction, setConstruction] = useState('');
    // -----------------------------
    const [governmentContractingAgency2, setGovernmentContractingAgency2] = useState('');
    const [implementingAgency, setImplementingAgency] = useState('');
    const [preparatingAgency, setPreparatingAgency] = useState('');
    const [capitalExpenditure, setCapitalExpenditure] = useState('');
    const [operationalExpenditure, setOperationalExpenditure] = useState('');
    const [estimatedConcessionPeriod2, setEstimatedConcessionPeriod2] = useState('');
    const [location2, setLocation2] = useState('');
    // -----------------------------
    const [name, setName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    // -----------------------------
    const [arrayStatus, setArrayStatus] = useState([{value:"", label:"Choose"}]);
    const [arrayRoi, setArrayRoi] = useState([{value:"", label:"Choose"}]);
    const [arrayStatusProject, setArrayStatusProject] = useState([{value:"", label:"Choose"}]);
    const [arrayGcam, setArrayGcam] = useState([{value:"", label:"Choose"}]);
    const [arraySector, setArraySector] = useState([{value:"", label:"Choose"}]);
    const [arraySubsector, setArraySubsector] = useState([{value:"", label:"Choose"}]);
    const [arrayTypeOfPPP, setArrayTypeOfPPP] = useState([{value:"", label:"Choose"}]);
    
  //===========cost structure new++++++++++
    const [opex, setOpex] = useState('');
    const [capex, setCapex] = useState('');
    const [firr2, setFirr2] = useState('');
    const [eirr, setEirr] = useState('');
    const [npv2, setNpv2] = useState('');
    const [descCostStructure, setDescCostStructure] = useState('');
    const [descSpec, setDescSpec] = useState('');
    
    // const [opportunity, setOpportunity] = useState([])

    const [technicalSpecification, setTechnicalSpecification] = useState([])
    const arrTechnicalSpesification = {
        technicalSpecification: technicalSpecification,
        setTechnicalSpecification: setTechnicalSpecification,
        desc:{
          name: descSpec,
          onchange: setDescSpec,
          placeholder: "Description Technical Specification"
        }
    }
    const [costItem, setCostItem] = useState([])
    const arrCostItem = {
        costItem: costItem,
        setCostItem: setCostItem
    }


    //---------Add Opportunity Variable-----------
    const [opportunity, setOpportunity] = useState(
        {
            opportunityContext:"",
            businessEntityContext:"",
            environmentImpactContext:"",
            governmentSupportContext:"",
            landAcquisitionContext:"",
            projectPictureProfileContext:"",
            indicativeProjectStructureContext:"",
            projectPictureContext:"",
        }
    )

    let dataSemesta = {
        documentYear: projectYear,
        documentProjectName: projectName,
        documentProjectStatus : projectStatus,
        documentLocation: location,
        documentGovernmentContractingAgency: governmentContractingAgency,
        documentType: typeofPPP,
        documentReturnOfInvesment: returnOfInvesment,
        documentSector: sector,
        documentSubSector: subSector,
        documentDescription: description,
        documentestimatedProjectValueCap: estimatedProjectValueCap,
        documentestimatedProjectValueOps: estimatedProjectValueOps,
        documentEquityIRR: equityIrr,
        documentFIRR: firr,
        documentNPV: npv,
        documentEstimatedConcessionPeriod: estimatedConcessionPeriod,
        documentStatus : projectStatus2,
        documentOBC : OBC,
        documentFBC : FBC,
        documentPreQualification : preQualification,
        documentRequestForProposal : requestForProposal,
        documentAgreementSigning : aggrementSigning,
        documentBidAward : bidAward,
        documentFinancialClose : financialClose,
        documentConstruction : construction,
        documentGovernmentContractingAgency2 : governmentContractingAgency2,
        documentImplementingAgency : implementingAgency,
        documentPreparatingAgency : preparatingAgency,
        documentCapitalExpenditure : capitalExpenditure,
        documentCapitalExpenditure : operationalExpenditure,
        documentEstimatedConcessionPeriod2 : estimatedConcessionPeriod2,
        documentLocation2 : location2,
        documentName : name,
        documentContactPerson : contactPerson,
        documentPhone : phone,
        documentEmail : email,
        documentOpportunity : opportunity.opportunityContext,
        documentBusinessEntity : opportunity.businessEntityContext,
        documentEnvironmentImpact : opportunity.environmentImpactContext,
        documentGovernmentSupport : opportunity.governmentSupportContext,
        documentLandAcquisition : opportunity.landAcquisitionContext,
        documentTechnicalSpecification: technicalSpecification,
        ///////////////////cost structure new///////////////
        documentProjectCostStructure: [{
          capex: capex,
          opex: opex,
          firr: firr2,
          eirr: eirr,
          npv: npv2
        }]
    }

    const arrProjectForm = [
        {"name":projectYear,"onchange": setProjectYear,placeholder: "Year",input: "text"},
        {"name":projectName,"onchange": setProjectName,placeholder: "Project Name",input: "text"},
        {"name":projectStatus,"onchange": setProjectStatus,placeholder: "Status",input: "dropdown", listArray: arrayStatus},
        {"name":location,"onchange": setLocation,placeholder: "Location",input: "text"},
        {"name":typeofPPP,"onchange": setTypeofPPP,placeholder: "TypeofPPP",input: "dropdown",listArray: arrayTypeOfPPP},
        {"name":returnOfInvesment,"onchange": setReturnOfInvesment,placeholder: "Return Of Invesment",input: "text"},
        {"name":sector,"onchange": setSector,placeholder: "Sector",input: "dropdown",listArray: arraySector},
        {"name":subSector,"onchange": setSubSector,placeholder: "SubSector",input: "dropdown",listArray: arraySubsector},
        {"name":estimatedProjectValueCap,"onchange": setEstimatedProjectValueCap,placeholder: "Estimated Project ValueCap",input: "text"},
        {"name":estimatedProjectValueOps,"onchange": setEstimatedProjectValueOps,placeholder: "Estimated ProjectValue Ops",input: "text"},
        {"name":governmentContractingAgency,"onchange": setGovernmentContractingAgency,placeholder: "Government Contracting Agency",input: "dropdown",listArray: arrayGcam},
        {"name":equityIrr,"onchange": setEquityIrr,placeholder: "Equity Irr",input: "text"},
        {"name":firr,"onchange": setFirr,placeholder: "Firr",input: "text"},
        {"name":npv,"onchange": setNpv,placeholder: "Npv",input: "text"},
        {"name":estimatedConcessionPeriod,"onchange": setEstimatedConcessionPeriod,placeholder: "Estimated ConcessionPeriod",input: "text"},
        {"name":description,"onchange": setDescription,placeholder: "Description",input: "textarea"}
    ]

    const arrIndicativeProjectScheduleForm = [
        {"name": projectStatus2,"onchange": setProjectStatus2,placeholder: "Project Status", class: "sm:col-span-2 mx-2"},
        {"name": OBC,"onchange": setOBC,placeholder: "OBC", class: "sm:col-span-1 mx-2"},
        {"name": FBC,"onchange": setFBC,placeholder: "FBC", class: "sm:col-span-1 mx-2"},
        {"name": preQualification,"onchange": setPreQualification,placeholder: "Pre Qualification", class: "sm:col-span-1 mx-2"},
        {"name": requestForProposal,"onchange": setRequestForProposal,placeholder: "Request For Proposal", class: "sm:col-span-1 mx-2"},
        {"name": aggrementSigning,"onchange": setAggrementSigning,placeholder: "Aggrement Signing", class: "sm:col-span-1 mx-2"},
        {"name": bidAward,"onchange": setBidAward,placeholder: "Bid Award", class: "sm:col-span-1 mx-2"},
        {"name": financialClose,"onchange": setFinancialClose,placeholder: "Financial Close", class: "sm:col-span-1 mx-2"},
        {"name": construction,"onchange": setConstruction,placeholder: "Construction", class: "sm:col-span-1 mx-2"},
    ]

    const arrAddProjectDigest = [
        {"name": governmentContractingAgency2,"onchange": setGovernmentContractingAgency2, placeholder: "Government Contracting Agency2", class: "sm:col-span-2 mx-2"},
        {"name": implementingAgency,"onchange": setImplementingAgency, placeholder: "Implementing Agency", class: "sm:col-span-2 mx-2"},
        {"name": preparatingAgency,"onchange": setPreparatingAgency, placeholder: "Preparating Agency", class: "sm:col-span-2 mx-2"},
        {"name": capitalExpenditure,"onchange": setCapitalExpenditure, placeholder: "Capital Expenditure", class: "sm:col-span-2 mx-2"},
        {"name": operationalExpenditure,"onchange": setOperationalExpenditure, placeholder: "Operational Expenditure", class: "sm:col-span-2 mx-2"},
        {"name": estimatedConcessionPeriod2,"onchange": setEstimatedConcessionPeriod2, placeholder: "Estimated Concession Period2", class: "sm:col-span-2 mx-2"},
        {"name": location2,"onchange": setLocation2, placeholder: "Location 2", class: "sm:col-span-2 mx-2"},

    ]
    const arrContactInformation = [
        {"name": name,"onchange": setName, placeholder: "Name", class: "sm:col-span-2 mx-2"},
        {"name": contactPerson,"onchange": setContactPerson, placeholder: "Posirtion", class: "sm:col-span-2 mx-2"},
        {"name": phone,"onchange": setPhone, placeholder: "Phone", class: "sm:col-span-2 mx-2"},
        {"name": email,"onchange": setEmail, placeholder: "Email", class: "sm:col-span-2 mx-2"},
    ]
//===========cost structure new++++++++++
    const arrayCostStructureNew = {
      capex:{
        name: capex,
        onchange: setCapex,
        placeholder: "Capex",
      },
      opex:{
        name: opex,
        onchange: setOpex,
        placeholder: "Opex",
      },
      firr:{
        name: firr2,
        onchange: setFirr2,
        placeholder: "FIRR",
      },
      eirr:{
        name: eirr,
        onchange: setEirr,
        placeholder: "EIRR",
      },
      npv:{
        name: npv2,
        onchange: setNpv2,
        placeholder: "NPV",
      },
      desc:{
        name: descCostStructure,
        onchane: setDescCostStructure,
        placeholder: "Description Cost Structure"
      }
    }

    const arrayComponentTitle = [
        "Project Form",
        "Indicative Project Schedule Form",
        "Add Project Picture Profile",
        "Add Project Digest Page",
        "Add Opportunity",
        "Add Business Entity Scope Of Work",
        "Technical Specification",
        "Add Environment Impact",
        "Add Land Acquisition and Resettlement Action Plan",
        "Project Cost Structure",
        "Add Government Support",
        "Contact Information",
        "Add Indicative Project Structure",
        "Add Project Picture",
    ]

    const arrayComponent = [
      <ProjectForm data={arrProjectForm}/>,
      <IndicativeProjectScheduleForm data={arrIndicativeProjectScheduleForm}/>,
      <ProjectPictureProfile/>,
      <AddProjectDigestPageSecond data={arrAddProjectDigest}/>,
      <AddOpportunity/>,
      <AddBusinessEntityScopeOfWork/>,
      <TechnicalSpecification data={arrTechnicalSpesification}/>,
      <AddEnvironmentImpact/>,
      <AddLandAcquisition/>,
      <ProjectCostStructure data={arrCostItem}/>,
      <AddGovernmentSupport/>,
      <ContactInformation data={arrContactInformation}/>,
      <IndicativeProjectStructure/>,
      <ProjectPicture/>,
  ]
    
    const getDataStatus = async () => {
        await axios.get(`${myApi.URLPROD}/statuses/listStatusAll`, {}).then((res) => {
          if (res.status == 200) {
            if(res.data.length != 0) {
              let option = [{value:"", label:"Choose"}]
              res.data.map((val)=>{
                option.push({value: val._id,label: val.statusName})
              })
              setArrayStatus(option)
              // console.log(arrayStatus)
            }
          } else {
            console.log("something wrong")
          }
          console.log(res)
        })
      }

    const getDataTypeOfPPP = async () => {
        await axios.get(`${myApi.URLPROD}/typeofppp/listTypeOfPPPAll`, {}).then((res) => {
          if (res.status == 200) {
            if(res.data.length != 0) {
              let option = [{value:"", label:"Choose"}]
              res.data.map((val)=>{
                option.push({value: val._id,label: val.typeOfPPPName})
              })
              setArrayTypeOfPPP(option)
              // console.log(arrayStatus)
            }
          } else {
            console.log("something wrong")
          }
          console.log(res)
        })
      }

    const getDataRoi = async () => {
        await axios.get(`${myApi.URLPROD}/roi/listRoiAll`, {}).then((res) => {
          if (res.status == 200) {
            if(res.data.length != 0) {
              let option = [{value:"", label:"Choose"}]
              res.data.map((val)=>{
                option.push({value: val._id,label: val.roiName})
              })
              setArrayGcam(option)
              // console.log(arrayStatus)
            }
          } else {
            console.log("something wrong")
          }
          console.log(res)
        })
      }

    const getDataSector = async () => {
        await axios.get(`${myApi.URLPROD}/sector/listSectorAll`, {}).then((res) => {
          if (res.status == 200) {
            if(res.data.length != 0) {
              let option = [{value:"", label:"Choose"}]
              res.data.map((val)=>{
                option.push({value: val._id,label: val.sectorName})
              })
              setArraySector(option)
              // console.log(arrayStatus)
            }
          } else {
            console.log("something wrong")
          }
          console.log(res)
        })
      }
    
    const getDataSubsector = async () => {
        await axios.get(`${myApi.URLPROD}/subsector/listSubsectorAll`, {}).then((res) => {
          if (res.status == 200) {
            if(res.data.length != 0) {
              let option = [{value:"", label:"Choose"}]
              res.data.map((val)=>{
                option.push({value: val._id,label: val.subsectorName})
              })
              setArraySubsector(option)
              // console.log(arrayStatus)
            }
          } else {
            console.log("something wrong")
          }
          console.log(res)
        })
      }

    const getDataGcam = async () => {
        await axios.get(`${myApi.URLPROD}/gcam/listGcamAll`, {}).then((res) => {
          if (res.status == 200) {
            if(res.data.length != 0) {
              let option = [{value:"", label:"Choose"}]
              res.data.map((val)=>{
                option.push({value: val._id,label: val.gcamName})
              })
              setArrayGcam(option)
              // console.log(arrayStatus)
            }
          } else {
            console.log("something wrong")
          }
          console.log(res)
        })
      }

    const [visibleContent, setVisibleContent] = useState(0)
    const changeForm = (val) => {
        if(val == "min" && visibleContent != 0) setVisibleContent(visibleContent - 1)
        if(val == "plus" && visibleContent != arrayComponent.length-1) setVisibleContent(visibleContent + 1)
    }

    const resetForm = (arr) => {
        arr.forEach(element => {
            element("")
        });
    }

    const sendData = async (event) => {
        // console.log("abcd")
        Swal.fire({
            title: "Do you want to save the changes?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
        }).then(async (result) => {
        if (result.isConfirmed) {
            setLoading(true)
            try {
                console.log("data semesta",dataSemesta)
                await axios.post(`${myApi.URLPROD}/documents/createDocument`, dataSemesta).then((res) => {
                    console.log(res.status)
                    if (res.status == 200) {
                    resetForm([
                        setProjectName,setLocation,setGovernmentContractingAgency,setTypeofPPP,setReturnOfInvesment,setSubSector,setDescription,setEstimatedProjectValueCap,setEstimatedProjectValueCap,setEstimatedProjectValueOps,setEquityIrr,setFirr,setNpv,setEstimatedConcessionPeriod,
                        setProjectStatus,
                        setOBC,
                        setFBC,
                        setPreQualification,
                        setSector,
                        setRequestForProposal,
                        setAggrementSigning,
                        setBidAward,
                        setFinancialClose,
                        setConstruction,
                        setGovernmentContractingAgency2,
                        setImplementingAgency,
                        setPreparatingAgency,
                        setCapitalExpenditure,
                        setOperationalExpenditure,
                        setEstimatedConcessionPeriod2,
                        setLocation2,            
                        setName,
                        setContactPerson,
                        setPhone,
                        setEmail,
                    ])
                    Swal.fire({
                        title: 'Saved',
                        text: 'Your work has been saved!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        })
                    window.location.reload();
                    } else {
                    Swal.fire({
                        title: 'Failed',
                        text: "Changes are not saved",
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                    }
                    console.log(res)
                })
                } catch (error) {
                Swal.fire({
                    title: 'Failed',
                    text: error,
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
                }
                setLoading(false)
        } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
        }
        });
    }

    useEffect( () => {
        getDataStatus()
        getDataGcam()
        getDataTypeOfPPP()
        getDataRoi()
        getDataSector()
        getDataSubsector()
        getDataSubsector()
    }, [])
    return(
        <OpportunityContext.Provider value={[opportunity,setOpportunity]}>
            <div className='flex bg-gray-200'>
                <div>
                    <SideBar/>
                </div>
                <div className='w-full'>
                    <Navbar/>
                    <div className='m-8 p-4 bg-white'>
                    <div className="flex flex-row justify-center">
                                <div className='px-16 hover:bg-gray-200' onClick={()=>{changeForm("min")}}>
                                    <span className='hover:bg-gray-200'><ChevronLeft/></span>                            
                                </div>
                                <h1 className="text-base text-center text-xl font-bold leading-7 text-gray-900">{arrayComponentTitle[visibleContent]}</h1>
                                <div className='px-16 hover:bg-gray-200' onClick={()=>{changeForm("plus")}}>
                                    <span className='hover:bg-gray-200'><ChevronRight/></span>
                                </div>
                            </div>
                        {
                            arrayComponent[visibleContent]
                        }
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <Button1 type="button" text="Simpan" onPress={()=>{}}></Button1>
                            <Button1 type="button" text="Reset" onPress={()=>{}}></Button1>
                            <Button1 type="button" text="Submit" onPress={()=>{sendData()}}></Button1>
                        </div>    
                    </div>
                </div>
            </div>
        </OpportunityContext.Provider>
    )
}

export default AddProjectDigestPage;
import React, { useEffect, useState, useContext, createContext } from 'react';
import axios from "axios";
import Navbar from '../../navigation/navbar';
import SideBar from '../../navigation/sidebar';
import ProjectCostStructure from '../../components/templates/form/projectCostStructure';
import TechnicalSpecification from '../../components/templates/form/technicalSpecification';
import ProjectForm from '../../components/templates/form/projectForm';
import IndicativeProjectScheduleForm from '../../components/templates/form/indicativeProjectSchedule';
import AddProjectDigestPageSecond from '../../components/templates/form/addProjectDigest';
import ContactInformation from '../../components/templates/form/contactInformation';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Button1 from '../../../src/components/atoms/button/button1';
import Swal from 'sweetalert2'
import { myApi } from '../../config/service/api'
import AddOpportunity from '../../components/templates/form/addOpportunity';
import AddBusinessEntityScopeOfWork from '../../components/templates/form/addBusinessEntityScopeOfWork';
import AddEnvironmentImpact from '../../components/templates/form/addEnvImpactAssesment';
import AddGovernmentSupport from '../../components/templates/form/addGovernmentSupport';
import AddLandAcquisition from '../../components/templates/form/addLandAcquisition';
import ProjectPictureProfile from '../../components/templates/form/projectPictureProfile';
import IndicativeProjectStructure from '../../components/templates/form/indicativeProjectStructure';
import ProjectPicture from '../../components/templates/form/projectPicture';
import { DataContext } from '../../config/context';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectCostStructureNew from '../../components/templates/form/projectCostStructureNew';

const DetailForm = ({ typeForm }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [dataContext, setDataContext] = useContext(DataContext)
  const [isLoading, setIsLoading] = useState({
    getAllDataByIdLoading: false,
    getDataStatusLoading: false,
    getDataStatusProjectLoading: false,
    getDataTypeOfPPPLoading: false,
    getDataRoiLoading: false,
    getDataSectorLoading: false,
    getDataSubsectorLoading: false,
    getDataGcamLoading: false

  })
  const getAllDataById = async () => {
    if (id) {
      setIsLoading(e => ({
        ...e, getAllDataByIdLoading: true
      }))
      await axios.get(myApi.URLPROD + '/documents/documentById/' + id)
        .then(async (response) => {
          if (response.status == 200) {
            // console.log(response.data[0])
            setCreatedBy(response.data?.[0]?.documentCreatedBy)
            setModifiedBy(response.data?.[0]?.documentModifiedBy)
            //-------------Project Form----------------
            setManualEdit(response.data[0]?.documentManualEdit)
            setProjectYear(response.data[0].documentYear)
            setProjectInitialYear(response.data[0].documentInitialYear)
            setProjectName(response.data[0].documentProjectName)
            setProjectCategory(response.data[0].documentProjectCategory)
            setProjectStatus(response.data[0].documentProjectStatus)
            setProjectSubBab(response?.data?.[0]?.documentSubBab)
            setArrayStatus([{ value: response.data[0].documentProjectStatus, label: response.data[0].documentProjectStatus }])
            setLocation(response.data[0].documentLocation)
            setGovernmentContractingAgency(response.data[0].documentGovernmentContractingAgency)
            setTypeofPPP(response.data[0].documentType)
            setReturnOfInvesment(response.data[0].documentReturnOfInvesment)
            setSector(response.data[0].documentSector)
            setSubSector(response.data[0].documentSubSector)
            setDescription(response.data[0].documentDescription)
            setEstimatedProjectValueCap(response.data[0].documentestimatedProjectValueCap)
            setEstimatedProjectValueOps(response.data[0].documentestimatedProjectValueOps)
            setEquityIrr(response.data[0].documentEquityIRR)
            setFirr(response.data[0].documentFIRR)
            setNpv(response.data[0].documentNPV)
            setEstimatedConcessionPeriod(response.data[0].documentEstimatedConcessionPeriod)
            if (dataContext.disableEditDataManagement) {
              setArrayTypeOfPPP([{ value: response.data[0].documentType, label: response.data[0].documentType }])
              setArraySector([{ value: response.data[0].documentSector, label: response.data[0].documentSector }])
              setArraySubsector([{ value: response.data[0].documentSubSector, label: response.data[0].documentSubSector }])
              setArrayGcam([{ value: response.data[0].documentGovernmentContractingAgency, label: response.data[0].documentGovernmentContractingAgency }])
            }
            //--------------Indicative Project Schedule Form---------------
            setProjectStatus2(response.data[0].documentStatus)
            setOBC(response.data[0].documentOBC)
            setFBC(response.data[0].documentFBC)
            setPreQualification(response.data[0].documentPreQualification)
            setRequestForProposal(response.data[0].documentRequestForProposal)
            setAggrementSigning(response.data[0].documentAgreementSigning)
            setBidAward(response.data[0].documentBidAward)
            setFinancialClose(response.data[0].documentFinancialClose)
            setConstruction(response.data[0].documentConstruction)
            //--------------Additional IPS Success Story---------------
            setPreFeasibilityStudy(response.data[0].documentPreFeasibilityStudy)
            setFeasibilityStudy(response.data[0].documentFeasibilityStudy)
            setFsEvaluation(response.data[0].documentFsEvaluation)
            setRequestForQualification(response.data[0].documentRequestForQualification)
            setAmmandementOfRequestOfProposal(response.data[0].documentAmmandementOfRequestOfProposal)
            setOperationIPSSuccessStory(response.data[0].documentOperationIPSSuccessStory)
            //-----------------------------
            setGovernmentContractingAgency2(response.data[0].documentGovernmentContractingAgency2)
            setImplementingAgency(response.data[0].documentImplementingAgency)
            setPreparatingAgency(response.data[0].documentPreparatingAgency)
            setCapitalExpenditure(response.data[0].documentCapitalExpenditure)
            setOperationalExpenditure(response.data[0].documentOperationalExpenditure)
            setEstimatedConcessionPeriod2(response.data[0].documentEstimatedConcessionPeriod2)
            setLocation2(response.data[0].documentLocation)
            // -----------------------------
            setName(response.data[0].documentName)
            setContactPerson(response.data[0].documentContactPerson)
            setPhone(response.data[0].documentPhone)
            setEmail(response.data[0].documentEmail)
            //-------------------------------
            setProjectPicture(response.data[0].documentProjectPicture)
            setProjectPictureProfile(response.data[0].documentProjectPictureProfile)
            setIndicativeProjectStructure(response.data[0].documentProjectPictureProfile)
            //-------------------------------
            setOpex(response.data[0].documentProjectCostStructure[0]?.opex)
            setCapex(response.data[0].documentProjectCostStructure[0]?.capex)
            setFirr2(response.data[0].documentProjectCostStructure[0]?.firr)
            setEirr(response.data[0].documentProjectCostStructure[0]?.eirr)
            setNpv2(response.data[0].documentProjectCostStructure[0]?.npv)
            setDescCostStructure(response.data[0].documentProjectCostDescription)
            //-------------------------------
            setFinancier(response.data[0].documentFinancier)
            setInvestor(response.data[0].documentInvestor)
            setGovernmentSupportGuarantee(response.data[0].documentGovernmentSupportGuarantee)
            setImplementationSchedule(response.data[0].documentImplementationSchedule)
            //-------------------------------
            setDataContext(e => ({
              ...e,
              opportunityContext: response.data[0].documentOpportunity,
              businessEntityContext: response.data[0].documentBusinessEntity,
              environmentImpactContext: response.data[0].documentEnvironmentImpact,
              governmentSupportContext: response.data[0].documentGovernmentSupport,
              landAcquisitionContext: response.data[0].documentLandAcquisition,
              projectPictureProfileContext: response.data[0].documentProjectPictureProfile,
              indicativeProjectStructureContext: response.data[0].documentIndicativeProjectStructure,
              projectPictureContext: response.data[0].documentProjectPicture,
              technicalSpecification: response.data[0].documentTechnicalSpecification,
              descriptionProjectPicture: response.data[0].documentDescriptionProjectPicture,
              contactInformation: response.data[0].documentContactInformation,
              specDescription: response.data[0].documentSpecDescription,
            }))
            // setTechnicalSpecification(response.data[0].documentTechnicalSpecification)
            setCostItem(response.data[0].documentCostItem)
            setIsLoading(e => ({
              ...e, getAllDataByIdLoading: false
            }))
          }

        }).catch((err) => {
          console.log(err)
          Swal.fire({
            title: err.message,
            icon: "warning"
          });
          setIsLoading(e => ({
            ...e, getAllDataByIdLoading: false
          }))
        })
    }
  }

  const [createdBy, setCreatedBy] = useState("");
  const [modifiedBy, setModifiedBy] = useState("");
  const [Loading, setLoading] = useState([]);
  const [projectInitialYear, setProjectInitialYear] = useState('');
  const [projectYear, setProjectYear] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectCategory, setProjectCategory] = useState('');
  const [projectStatus, setProjectStatus] = useState('');
  const [projectSubBab, setProjectSubBab] = useState('');
  const [location, setLocation] = useState('');
  const [governmentContractingAgency, setGovernmentContractingAgency] = useState('');
  const [typeofPPP, setTypeofPPP] = useState('');
  const [returnOfInvesment, setReturnOfInvesment] = useState('');
  const [sector, setSector] = useState('');
  const [subSector, setSubSector] = useState('');
  const [description, setDescription] = useState('');
  const [estimatedProjectValueCap, setEstimatedProjectValueCap] = useState('');
  const [estimatedProjectValueOps, setEstimatedProjectValueOps] = useState('');
  const [equityIrr, setEquityIrr] = useState('');
  const [firr, setFirr] = useState('');
  const [npv, setNpv] = useState('');
  const [estimatedConcessionPeriod, setEstimatedConcessionPeriod] = useState('');
  // -----------------------------
  const [projectStatus2, setProjectStatus2] = useState('');
  const [OBC, setOBC] = useState('');
  const [FBC, setFBC] = useState('');
  const [preQualification, setPreQualification] = useState('');
  const [requestForProposal, setRequestForProposal] = useState('');
  const [aggrementSigning, setAggrementSigning] = useState('');
  const [bidAward, setBidAward] = useState('');
  const [financialClose, setFinancialClose] = useState('');
  const [construction, setConstruction] = useState('');
  // ------------Additional IPS Success Story-----------------
  const [preFeasibilityStudy, setPreFeasibilityStudy] = useState('');
  const [feasibilityStudy, setFeasibilityStudy] = useState('');
  const [fsEvaluation, setFsEvaluation] = useState('');
  const [ammandementOfRequestOfProposal, setAmmandementOfRequestOfProposal] = useState('');
  const [operationIPSSuccessStory, setOperationIPSSuccessStory] = useState('');
  const [requestForQualification, setRequestForQualification] = useState('')
  // -----------------------------
  const [governmentContractingAgency2, setGovernmentContractingAgency2] = useState('');
  const [implementingAgency, setImplementingAgency] = useState('');
  const [preparatingAgency, setPreparatingAgency] = useState('');
  const [capitalExpenditure, setCapitalExpenditure] = useState('');
  const [operationalExpenditure, setOperationalExpenditure] = useState('');
  const [estimatedConcessionPeriod2, setEstimatedConcessionPeriod2] = useState('');
  const [location2, setLocation2] = useState('');
  // -----------------------------
  const [name, setName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  // -----------------------------
  const [projectPicture, setProjectPicture] = useState('');
  const [projectPictureProfile, setProjectPictureProfile] = useState('');
  const [indicativeProjectStructure, setIndicativeProjectStructure] = useState('');
  //------------------------------
  const [investor, setInvestor] = useState('');
  const [financier, setFinancier] = useState('');
  const [governmentSupportGuarantee, setGovernmentSupportGuarantee] = useState('');
  const [implementationSchedule, setImplementationSchedule] = useState('');
  // -----------------------------
  const [arrayStatus, setArrayStatus] = useState([{ value: "", label: "Choose" }]);
  const [arraySubBab, setArraySubBab] = useState([{ value: "", label: "Choose" }]);
  const [arrayRoi, setArrayRoi] = useState([{ value: "", label: "Choose" }]);
  const [arrayStatusProject, setArrayStatusProject] = useState([{ value: "", label: "Choose" }]);
  const [arrayGcam, setArrayGcam] = useState([{ value: "", label: "Choose" }]);
  const [arraySector, setArraySector] = useState([{ value: "", label: "Choose" }]);
  const [arraySubsector, setArraySubsector] = useState([{ value: "", label: "Choose" }]);
  const [arrayTypeOfPPP, setArrayTypeOfPPP] = useState([{ value: "", label: "Choose" }]);
  const [arrayProjectStatus2, setArrayProjectStatus2] = useState([]);
  const [arrayProjectCategory, setArrayProjectCategory] = useState([{ value: "", label: "Choose" }]);
  const [contactInformation, setContactInformation] = useState([]);
  // -----------------------------
  const [manualEdit, setManualEdit] = useState(false);

  //===========cost structure new++++++++++
  const [opex, setOpex] = useState('');
  const [capex, setCapex] = useState('');
  const [firr2, setFirr2] = useState('');
  const [eirr, setEirr] = useState('');
  const [npv2, setNpv2] = useState('');
  const [descCostStructure, setDescCostStructure] = useState('');
  const [descSpec, setDescSpec] = useState('');
  //===========cost structure new++++++++++
  const arrayCostStructureNew = {
    capex: {
      name: capitalExpenditure,
      onchange: setCapitalExpenditure,
      placeholder: "Capex",
    },
    opex: {
      name: operationalExpenditure,
      onchange: setOperationalExpenditure,
      placeholder: "Opex",
    },
    firr: {
      name: firr,
      onchange: setFirr,
      placeholder: "Firr",
    },
    eirr: {
      name: equityIrr,
      onchange: setEquityIrr,
      placeholder: "Eirr",
    },
    npv: {
      name: npv,
      onchange: setNpv,
      placeholder: "Npv",
    },
    desc: {
      name: descCostStructure,
      onchange: setDescCostStructure,
      placeholder: "Description Cost Structure"
    }
  }

  const [technicalSpecification, setTechnicalSpecification] = useState([])
  const arrTechnicalSpesification = {
    technicalSpecification: technicalSpecification,
    setTechnicalSpecification: setTechnicalSpecification,
    desc: {
      name: descSpec,
      onchange: setDescSpec,
      placeholder: "Description Technical Specification"
    }
  }
  const [costItem, setCostItem] = useState([])
  const arrCostItem = {
    costItem: costItem,
    setCostItem: setCostItem,
  }

  let dataSemesta = {
    documentManualEdit: manualEdit,
    documentInitialYear: projectInitialYear,
    documentYear: projectYear,
    documentProjectName: projectName,
    documentProjectCategory: projectCategory,
    documentProjectStatus: projectStatus,
    documentSubBab: projectSubBab,
    documentLocation: location,
    documentGovernmentContractingAgency: governmentContractingAgency,
    documentType: typeofPPP,
    documentReturnOfInvesment: returnOfInvesment,
    documentSector: sector,
    documentSubSector: subSector,
    documentDescription: description,
    documentestimatedProjectValueCap: estimatedProjectValueCap,
    documentestimatedProjectValueOps: estimatedProjectValueOps,
    documentEquityIRR: equityIrr,
    documentFIRR: firr,
    documentNPV: npv,
    documentEstimatedConcessionPeriod: estimatedConcessionPeriod,
    documentStatus: projectStatus2,
    documentOBC: OBC,
    documentFBC: FBC,
    documentPreQualification: preQualification,
    documentRequestForProposal: requestForProposal,
    documentAgreementSigning: aggrementSigning,
    documentBidAward: bidAward,
    documentFinancialClose: financialClose,
    documentConstruction: construction,
    documentGovernmentContractingAgency2: governmentContractingAgency2,
    documentImplementingAgency: implementingAgency,
    documentPreparatingAgency: preparatingAgency,
    documentCapitalExpenditure: capitalExpenditure,
    documentOperationalExpenditure: operationalExpenditure,
    documentEstimatedConcessionPeriod2: estimatedConcessionPeriod2,
    documentLocation2: location2,
    documentName: name,
    documentContactPerson: contactPerson,
    documentPhone: phone,
    documentEmail: email,
    documentOpportunity: dataContext.opportunityContext,
    documentBusinessEntity: dataContext.businessEntityContext,
    documentEnvironmentImpact: dataContext.environmentImpactContext,
    documentGovernmentSupport: dataContext.governmentSupportContext,
    documentLandAcquisition: dataContext.landAcquisitionContext,
    documentTechnicalSpecification: dataContext.technicalSpecification,
    documentCostItem: costItem,
    documentSpecDescription: dataContext.specDescription,
    documentProjectCostDescription: descCostStructure,
    documentFinancier: financier,
    documentInvestor: investor,
    documentGovernmentSupportGuarantee: governmentSupportGuarantee,
    documentImplementationSchedule: implementationSchedule,
    ///////////////////document cost structure new///////////////
    documentProjectCostStructure: [{
      capex: capex,
      opex: opex,
      firr: firr2,
      eirr: eirr,
    }],
    documentDescriptionProjectPicture: dataContext.descriptionProjectPicture,
    documentProjectPicture: dataContext.projectPictureContext,
    documentProjectPictureProfile: dataContext.projectPictureProfileContext,
    documentIndicativeProjectStructure: dataContext.indicativeProjectStructureContext,
    documentContactInformation: dataContext.contactInformation,
    documentPreFeasibilityStudy: preFeasibilityStudy,
    documentFeasibilityStudy: feasibilityStudy,
    documentFsEvaluation: fsEvaluation,
    documentRequestForQualification: requestForQualification,
    documentAmmandementOfRequestOfProposal: ammandementOfRequestOfProposal,
    documentOperationIPSSuccessStory: operationIPSSuccessStory,
    documentUserToken: localStorage.getItem("userToken"),
  }

  const arrProjectForm = [
    { "name": "manualEdit", value: manualEdit, "onchange": () => { setManualEdit(!manualEdit) }, placeholder: "Manual Edit", input: "checkbox", isDisabled: dataContext.disableEditDataManagement },
    { "name": projectInitialYear, "onchange": setProjectInitialYear, placeholder: "Initial Year", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": projectYear, "onchange": setProjectYear, placeholder: "Year", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": projectStatus, "onchange": setProjectStatus, placeholder: "Status", input: "dropdown", listArray: arrayStatus, isDisabled: dataContext.disableEditDataManagement },
    { "name": projectSubBab, "onchange": setProjectSubBab, placeholder: "Project Sub Bab", input: "dropdown", listArray: arraySubBab, isDisabled: dataContext.disableEditDataManagement },
    { "name": projectName, "onchange": setProjectName, placeholder: "Project Name", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": projectCategory, "onchange": setProjectCategory, placeholder: "Project Category", input: "dropdown", listArray: arrayProjectCategory, isDisabled: dataContext.disableEditDataManagement },
    { "name": location, "onchange": setLocation, placeholder: "Location", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": governmentContractingAgency, "onchange": setGovernmentContractingAgency, placeholder: "Government Contracting Agency", input: "dropdown", listArray: arrayGcam, isDisabled: dataContext.disableEditDataManagement },
    { "name": typeofPPP, "onchange": setTypeofPPP, placeholder: "TypeofPPP", input: "dropdown", listArray: arrayTypeOfPPP, isDisabled: dataContext.disableEditDataManagement },
    { "name": returnOfInvesment, "onchange": setReturnOfInvesment, placeholder: "Return Of Invesment", input: "dropdown", listArray: arrayRoi, isDisabled: dataContext.disableEditDataManagement },
    { "name": sector, "onchange": setSector, placeholder: "Sector", input: "dropdown", listArray: arraySector, isDisabled: dataContext.disableEditDataManagement },
    { "name": subSector, "onchange": setSubSector, placeholder: "SubSector", input: "dropdown", listArray: arraySubsector, isDisabled: dataContext.disableEditDataManagement },
    { "name": description, "onchange": setDescription, placeholder: "Description", input: "textarea", isDisabled: dataContext.disableEditDataManagement },
    { "name": estimatedProjectValueCap, "onchange": setEstimatedProjectValueCap, placeholder: "Capital Expenditure", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": estimatedProjectValueOps, "onchange": setEstimatedProjectValueOps, placeholder: "Operational Expenditure", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": equityIrr, "onchange": setEquityIrr, placeholder: "Equity Irr", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": firr, "onchange": setFirr, placeholder: "Firr", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": npv, "onchange": setNpv, placeholder: "Npv", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": estimatedConcessionPeriod, "onchange": setEstimatedConcessionPeriod, placeholder: "Estimated ConcessionPeriod", input: "text", isDisabled: dataContext.disableEditDataManagement },
  ]

  const arrProjectForm2 = [
    { "name": projectInitialYear, "onchange": setProjectInitialYear, placeholder: "Initial Year", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": projectYear, "onchange": setProjectYear, placeholder: "Year", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": projectName, "onchange": setProjectName, placeholder: "Project Name", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": projectCategory, "onchange": setProjectCategory, placeholder: "Project Category", input: "dropdown", listArray: arrayProjectCategory, isDisabled: dataContext.disableEditDataManagement },
    { "name": projectStatus, "onchange": setProjectStatus, placeholder: "Status", input: "dropdown", listArray: arrayStatus, isDisabled: dataContext.disableEditDataManagement },
    { "name": location, "onchange": setLocation, placeholder: "Location", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": sector, "onchange": setSector, placeholder: "Sector", input: "dropdown", listArray: arraySector, isDisabled: dataContext.disableEditDataManagement },
    { "name": subSector, "onchange": setSubSector, placeholder: "SubSector", input: "dropdown", listArray: arraySubsector, isDisabled: dataContext.disableEditDataManagement },
    { "name": projectStatus2, "onchange": setProjectStatus2, placeholder: "Project Status", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": governmentContractingAgency, "onchange": setGovernmentContractingAgency, placeholder: "Government Contracting Agency", input: "dropdown", listArray: arrayGcam, isDisabled: dataContext.disableEditDataManagement },
    { "name": investor, "onchange": setInvestor, placeholder: "Investor", input: "textarea", isDisabled: dataContext.disableEditDataManagement },
    { "name": financier, "onchange": setFinancier, placeholder: "Financier", input: "textarea", isDisabled: dataContext.disableEditDataManagement },
    { "name": governmentSupportGuarantee, "onchange": setGovernmentSupportGuarantee, placeholder: "Government Support and Guarantee", input: "textarea", isDisabled: dataContext.disableEditDataManagement },
    { "name": implementationSchedule, "onchange": setImplementationSchedule, placeholder: "Implementation Schedule", input: "textarea", isDisabled: dataContext.disableEditDataManagement },
    { "name": description, "onchange": setDescription, placeholder: "Description", input: "textarea", isDisabled: dataContext.disableEditDataManagement },
    { "name": capitalExpenditure, "onchange": setCapitalExpenditure, placeholder: "Capital Expenditur", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": operationalExpenditure, "onchange": setOperationalExpenditure, placeholder: "Operational Expenditur", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": equityIrr, "onchange": setEquityIrr, placeholder: "Equity Irr", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": firr, "onchange": setFirr, placeholder: "Firr", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": npv, "onchange": setNpv, placeholder: "Npv", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": estimatedConcessionPeriod, "onchange": setEstimatedConcessionPeriod, placeholder: "Concession Period", input: "text", isDisabled: dataContext.disableEditDataManagement },
  ]

  const arrIPSFormSuccessStory = [
    { "name": projectStatus2, "onchange": setProjectStatus2, placeholder: "Project Status", class: "sm:col-span-1 mx-2", input: "dropdown", listArray: arrayProjectStatus2, isDisabled: dataContext.disableEditDataManagement },
    { "name": preFeasibilityStudy, "onchange": setPreFeasibilityStudy, placeholder: "Pre-Feasibility Study", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": feasibilityStudy, "onchange": setFeasibilityStudy, placeholder: "Feasibility Study", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": fsEvaluation, "onchange": setFsEvaluation, placeholder: "FS Evaluation", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": OBC, "onchange": setOBC, placeholder: "OBC", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": FBC, "onchange": setFBC, placeholder: "FBC", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": preQualification, "onchange": setPreQualification, placeholder: "Pre Qualification", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": requestForQualification, "onchange": setRequestForQualification, placeholder: "Request For Qualification", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": requestForProposal, "onchange": setRequestForProposal, placeholder: "Request For Proposal", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": ammandementOfRequestOfProposal, "onchange": setAmmandementOfRequestOfProposal, placeholder: "Ammandement of Request for Proposal (Final)", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": bidAward, "onchange": setBidAward, placeholder: "Bid Award", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": aggrementSigning, "onchange": setAggrementSigning, placeholder: "Aggrement Signing", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": financialClose, "onchange": setFinancialClose, placeholder: "Financial Close", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": construction, "onchange": setConstruction, placeholder: "Construction", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": operationIPSSuccessStory, "onchange": setOperationIPSSuccessStory, placeholder: "Operation", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
  ]

  const arrIndicativeProjectScheduleForm = [
    { "name": projectStatus2, "onchange": setProjectStatus2, placeholder: "Project Status", input: "dropdown", listArray: arrayProjectStatus2, class: "sm:col-span-2 mx-2", isDisabled: dataContext.disableEditDataManagement },
    { "name": OBC, "onchange": setOBC, placeholder: "OBC", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": FBC, "onchange": setFBC, placeholder: "FBC", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": preQualification, "onchange": setPreQualification, placeholder: "Pre Qualification", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": requestForProposal, "onchange": setRequestForProposal, placeholder: "Request For Proposal", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": aggrementSigning, "onchange": setAggrementSigning, placeholder: "Aggrement Signing", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": bidAward, "onchange": setBidAward, placeholder: "Bid Award", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": financialClose, "onchange": setFinancialClose, placeholder: "Financial Close", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
    { "name": construction, "onchange": setConstruction, placeholder: "Construction", class: "sm:col-span-1 mx-2", input: "text", isDisabled: dataContext.disableEditDataManagement },
  ]

  const arrAddProjectDigest = [
    { "name": governmentContractingAgency, "onchange": setGovernmentContractingAgency, placeholder: "Government Contracting Agency2", class: "sm:col-span-2 mx-2", isDisabled: true },
    { "name": implementingAgency, "onchange": setImplementingAgency, placeholder: "Implementing Agency", class: "sm:col-span-2 mx-2", isDisabled: dataContext.disableEditDataManagement },
    { "name": preparatingAgency, "onchange": setPreparatingAgency, placeholder: "Preparating Agency", class: "sm:col-span-2 mx-2", isDisabled: dataContext.disableEditDataManagement },
    { "name": estimatedProjectValueCap, "onchange": setEstimatedProjectValueCap, placeholder: "Capital Expenditure", class: "sm:col-span-2 mx-2", isDisabled: true },
    { "name": estimatedProjectValueOps, "onchange": setEstimatedProjectValueOps, placeholder: "Operational Expenditure", class: "sm:col-span-2 mx-2", isDisabled: true },
    { "name": estimatedConcessionPeriod, "onchange": setEstimatedConcessionPeriod, placeholder: "Estimated Concession Period2", class: "sm:col-span-2 mx-2", isDisabled: true },
    { "name": location, "onchange": setLocation, placeholder: "Location", class: "sm:col-span-2 mx-2", isDisabled: true },

  ]
  const arrContactInformation = [
    { "name": name, "onchange": setName, placeholder: "Name", class: "sm:col-span-2 mx-2", isDisabled: dataContext.disableEditDataManagement },
    { "name": contactPerson, "onchange": setContactPerson, placeholder: "Position", class: "sm:col-span-2 mx-2", isDisabled: dataContext.disableEditDataManagement },
    { "name": phone, "onchange": setPhone, placeholder: "Phone", class: "sm:col-span-2 mx-2", isDisabled: dataContext.disableEditDataManagement },
    { "name": email, "onchange": setEmail, placeholder: "Email", class: "sm:col-span-2 mx-2", isDisabled: dataContext.disableEditDataManagement },
  ]

  const arrayComponentTitle = [
    "Add Project",
    "Add Project Picture Profile",
    "Add Indicative Project Schedule",
    "Add Indicative Project Structure",
    "Add Project Digest",
    "Add Project Picture",
    "Add Opportunity",
    "Add Business Entity Scope Of Work",
    "Add Technical Specification",
    "Add Environment Impact",
    "Add Land Acquisition and Resettlement Action Plan",
    "Project Cost Structure",
    "Add Government Support",
    "Contact Information",
  ]

  const arrayComponent = [
    <ProjectForm data={typeForm == "successStory" || typeForm == "underConstruction" || typeForm == "underOperation" || typeForm == "agreementSigning" ? arrProjectForm2 : arrProjectForm} />,
    <ProjectPictureProfile data={projectPictureProfile} isDisabled={dataContext.disableEditDataManagement} />,
    <IndicativeProjectScheduleForm data={typeForm == "successStory" || typeForm == "underConstruction" || typeForm == "underOperation" || typeForm == "agreementSigning" ? arrIndicativeProjectScheduleForm : arrIPSFormSuccessStory} />,
    <IndicativeProjectStructure data={indicativeProjectStructure} isDisabled={dataContext.disableEditDataManagement} />,
    <AddProjectDigestPageSecond data={arrAddProjectDigest} />,
    <ProjectPicture data={projectPicture} isDisabled={dataContext.disableEditDataManagement} />,
    <AddOpportunity isDisabled={dataContext.disableEditDataManagement} />,
    <AddBusinessEntityScopeOfWork isDisabled={dataContext.disableEditDataManagement} />,
    <TechnicalSpecification data={arrTechnicalSpesification} isDisabled={dataContext.disableEditDataManagement} />,
    <AddEnvironmentImpact isDisabled={dataContext.disableEditDataManagement} />,
    <AddLandAcquisition isDisabled={dataContext.disableEditDataManagement} />,
    <ProjectCostStructureNew data={arrayCostStructureNew} />,
    <AddGovernmentSupport isDisabled={dataContext.disableEditDataManagement} />,
    <ContactInformation data={arrContactInformation} />,
  ]

  const getDataStatus = async () => {
    setIsLoading(e => ({
      ...e, getDataStatusLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/statuses/listStatusAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.statusName, label: val.statusName })
          })
          setArrayStatus(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataStatusLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataStatusLoading: false
      }))
    })
  }

  const getDataStatusProject = async () => {
    setIsLoading(e => ({
      ...e, getDataStatusProjectLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/projectstatus/listProjectStatusAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.projectStatusName, label: val.projectStatusName })
          })
          setArrayProjectStatus2(option)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataStatusProjectLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataStatusProjectLoading: false
      }))
    })
  }

  const getDataSubBab = async () => {
    setIsLoading(e => ({
      ...e, getDataStatusProjectLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/subBab/getSubBab`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.subBabName, label: val.subBabName })
          })
          setArraySubBab(option)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataStatusProjectLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataStatusProjectLoading: false
      }))
    })
  }

  const getDataTypeOfPPP = async () => {
    setIsLoading(e => ({
      ...e, getDataTypeOfPPPLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/typeofppp/listTypeOfPPPAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.typeOfPPPName, label: val.typeOfPPPName })
          })
          setArrayTypeOfPPP(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataTypeOfPPPLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataTypeOfPPPLoading: false
      }))
    })
  }

  const getDataRoi = async () => {
    setIsLoading(e => ({
      ...e, getDataRoiLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/roi/listRoiAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.roiName, label: val.roiName })
          })
          setArrayRoi(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataRoiLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataRoiLoading: false
      }))
    })
  }

  const getDataSector = async () => {
    setIsLoading(e => ({
      ...e, getDataSectorLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/sector/listSectorAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.sectorName, label: val.sectorName })
          })
          setArraySector(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataSectorLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataSectorLoading: false
      }))
    })
  }

  const getDataSubsector = async () => {
    setIsLoading(e => ({
      ...e, getDataSubsectorLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/subsector/listSubsectorAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.subsectorName, label: val.subsectorName })
          })
          setArraySubsector(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataSubsectorLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataSubsectorLoading: false
      }))
    })
  }

  const getDataGcam = async () => {
    setIsLoading(e => ({
      ...e, getDataGcamLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/gcam/listGcamAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.gcamName, label: val.gcamName })
          })
          setArrayGcam(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataGcamLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataGcamLoading: false
      }))
    })
  }

  const getDataProjectCategory = async () => {
    setIsLoading(e => ({
      ...e, getDataProjectCategoryLoading: true
    }))
    await axios.get(`${myApi.URLPROD}/projectCategory/listProjectCategoryAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val.projectCategoryName, label: val.projectCategoryName })
          })
          setArrayProjectCategory(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      setIsLoading(e => ({
        ...e, getDataProjectCategoryLoading: false
      }))
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: err.message,
        icon: "warning"
      });
      setIsLoading(e => ({
        ...e, getDataProjectCategoryLoading: false
      }))
    })
  }

  const projectFormSelection = () => {
    if (typeForm == 'readyToOffer') {
      setProjectIndex([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])
    } else if (typeForm == 'underPreparation') {
      setProjectIndex([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])
    } else if (typeForm == 'alreadyTendered') {
      setProjectIndex([0, 1, 2, 3, 13])
    } else if (typeForm == 'successStory') {
      setProjectIndex([0, 1, 3, 13])
    } else if (typeForm == 'agreementSigning') {
      setProjectIndex([0, 1, 2, 3, 13])
    } else if (typeForm == 'underOperation') {
      setProjectIndex([0, 1, 3, 13])
    } else if (typeForm == 'underConstruction') {
      setProjectIndex([0, 1, 3, 13])
    } else if (typeForm == 'underProcurement') {
      setProjectIndex([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])
    } else if (typeForm == 'readyForTransaction') {
      setProjectIndex([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])
    }
    // console.log("Project Index Updated:", projectIndex);
  }

  const [projectIndex, setProjectIndex] = useState([])
  const [visibleIndex, setVisibleIndex] = useState(0)
  const [visibleContent, setVisibleContent] = useState(0)
  const changeForm = (val) => {
    if (val == "min" && visibleIndex > 0) {
      setVisibleContent(projectIndex[visibleIndex - 1])
      setVisibleIndex(visibleIndex - 1)
    }
    if (val == "plus" && visibleIndex < projectIndex.length - 1) {
      setVisibleContent(projectIndex[visibleIndex + 1])
      setVisibleIndex(visibleIndex + 1)
    }
  }

  const resetForm = (arr) => {
    arr.forEach(element => {
      element("")
    });
  }

  const sendData = async (event) => {
    // console.log("abcd")
    Swal.fire({
      title: "Do you want to add new project ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          console.log("data semesta", dataSemesta)
          await axios.post(`${myApi.URLPROD}/documents/createDocument`, dataSemesta).then((res) => {
            console.log(res.status)
            if (res.status == 200) {
              resetForm([
                setProjectName, setLocation, setGovernmentContractingAgency, setTypeofPPP, setReturnOfInvesment, setSubSector, setDescription, setEstimatedProjectValueCap, setEstimatedProjectValueCap, setEstimatedProjectValueOps, setEquityIrr, setFirr, setNpv, setEstimatedConcessionPeriod,
                setProjectStatus,
                setOBC,
                setFBC,
                setPreQualification,
                setSector,
                setRequestForProposal,
                setAggrementSigning,
                setBidAward,
                setFinancialClose,
                setConstruction,
                setGovernmentContractingAgency2,
                setImplementingAgency,
                setPreparatingAgency,
                setCapitalExpenditure,
                setOperationalExpenditure,
                setEstimatedConcessionPeriod2,
                setLocation2,
                setName,
                setContactPerson,
                setPhone,
                setEmail,
              ])
              Swal.fire({
                title: 'Saved',
                text: 'Your work has been saved!',
                icon: 'success',
                confirmButtonText: 'OK',
              })
              window.location.reload()
            } else {
              Swal.fire({
                title: 'Failed',
                text: "New Project is not saved",
                icon: 'error',
                confirmButtonText: 'OK',
              })
            }
            console.log(res)
          })
        } catch (error) {
          Swal.fire({
            title: 'Failed',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
        setLoading(false)
      } else if (result.isDenied) {
        Swal.fire("New Project is not saved", "", "info");
      }
    });
  }

  const updateData = async (event) => {
    // console.log("abcd")
    Swal.fire({
      title: "Do you want to save the changes?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          console.log("data semesta", dataSemesta)
          await axios.post(`${myApi.URLPROD}/documents/updateDocument/${id}`, dataSemesta).then((res) => {
            console.log(res.status)
            if (res.status == 200) {
              resetForm([
                setProjectName, setLocation, setGovernmentContractingAgency, setTypeofPPP, setReturnOfInvesment, setSubSector, setDescription, setEstimatedProjectValueCap, setEstimatedProjectValueCap, setEstimatedProjectValueOps, setEquityIrr, setFirr, setNpv, setEstimatedConcessionPeriod,
                setProjectStatus,
                setOBC,
                setFBC,
                setPreQualification,
                setSector,
                setRequestForProposal,
                setAggrementSigning,
                setBidAward,
                setFinancialClose,
                setConstruction,
                setGovernmentContractingAgency2,
                setImplementingAgency,
                setPreparatingAgency,
                setCapitalExpenditure,
                setOperationalExpenditure,
                setEstimatedConcessionPeriod2,
                setLocation2,
                setName,
                setContactPerson,
                setPhone,
                setEmail,
              ])
              Swal.fire({
                title: 'Saved',
                text: 'Your work has been saved!',
                icon: 'success',
                confirmButtonText: 'OK',
              })
              // window.location.reload()
              navigate(`/${typeForm}/${id}`)
              getAllDataById()
            } else {
              Swal.fire({
                title: 'Failed',
                text: "Changes are not saved",
                icon: 'error',
                confirmButtonText: 'OK',
              })
            }
            console.log(res)
          })
        } catch (error) {
          Swal.fire({
            title: 'Failed',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
        setLoading(false)
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  useEffect(() => {
    setIsLoading(true)
    projectFormSelection()
    getAllDataById()
    getDataStatus()
    getDataSubBab()
    getDataGcam()
    getDataTypeOfPPP()
    getDataRoi()
    getDataSector()
    getDataSubsector()
    getDataStatusProject()
    getDataTypeOfPPP()
    getDataGcam()
    getDataRoi()
    getDataSector()
    getDataSubsector()
    getDataProjectCategory()
    if (typeForm === "readyToOffer") setProjectStatus("Ready To Offer")
    else if (typeForm === "underPreparation") setProjectStatus("Under Preparation")
    else if (typeForm === "alreadyTendered") setProjectStatus("Already Tendered")
    else if (typeForm === "successStory") setProjectStatus("Success Story")
    // getUserCreatedData()
    // getUserModifiedData()
    setIsLoading(false)
  }, [])
  return (
    <div className='flex bg-gray-200'>
      <div>
        <SideBar />
      </div>
      <div className='w-full'>
        <Navbar />
        {isLoading.getAllDataByIdLoading
          && isLoading.getDataStatusLoading
          && isLoading.getDataStatusProjectLoading
          && isLoading.getDataTypeOfPPPLoading
          && isLoading.getDataRoiLoading
          && isLoading.getDataSectorLoading
          && isLoading.getDataSubsectorLoading
          && isLoading.getDataGcamLoading
          ? <div className="h-svh flex-col gap-4 w-full flex items-center justify-center">
            <div className="w-28 h-28 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-400 rounded-full">
              <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" class="animate-ping">
              </svg>
            </div>
          </div>
          : <div className='m-8 p-4 bg-white'>
            <div className="flex flex-row justify-center">
              <div className='px-16 hover:bg-gray-200' onClick={() => { changeForm("min") }}>
                <span className='hover:bg-gray-200'><ChevronLeft /></span>
              </div>
              <h1 className="text-base text-center text-xl font-bold leading-7 text-gray-900">{arrayComponentTitle[visibleContent]}</h1>
              <div className='px-16 hover:bg-gray-200' onClick={() => { changeForm("plus") }}>
                <span className='hover:bg-gray-200'><ChevronRight /></span>
              </div>
            </div>
            {
              arrayComponent[visibleContent]
            }
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <div>Created By : {createdBy}</div>
              <div>Last Modified By : {modifiedBy}</div>
              {
                dataContext.disableEditDataManagement
                  ? <></>
                  : <>
                    {dataContext.addProject && !id
                      ? <Button1 type="button" text="Submit" onPress={() => { sendData() }}></Button1>
                      : <Button1 type="button" text="Update" onPress={() => { updateData() }}></Button1>
                    }
                  </>
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default DetailForm;
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight, Camera } from 'lucide-react'
import docIcon from '../../assets/images/doc.png'
import pdfIcon from '../../assets/images/pdf.png'
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import expressionParser from 'docxtemplater/expressions';
const fs = require("fs");
const path = require("path");

// function loadFile(url, callback) {
//     PizZipUtils.getBinaryContent(url, callback);
//   }

// const content = fs.readFileSync(
//     path.resolve(__dirname, "input.docx"),
//     "binary"
// );

const ExportDocx = () => {
  const generateDocument = () => {

    PizZipUtils.getBinaryContent(
      'http://localhost:5013/documents/download/successStory.docx',
      function (error, content) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: expressionParser,
        });
        doc.render({
          documentProjectName: "Development of Baubau Port",
          documentLocation: "ini dokumen lokasi",
          documentProjectPicture: "https://firebasestorage.googleapis.com/v0/b/pppbookbappenas-1b81a.appspot.com/o/projectPicture%2FPPP%20Book%202023_1-330_1476395186.jpg?alt=media&token=b0229ee0-1386-4d2f-a18f-bb3ab3f262c7",
          documentSubSector: "Port",
          documentDescription: "The Semanan - Balaraja Toll Road section, which is part of the Inner Jakarta Toll Road Network, will have a length of 32.39 km and feature 4 interchanges and 2 junctions. The toll road will start at the East Balaraja Interchange, located at the end of the Serpong-Balaraja Toll Road section.",
          documentCapitalExpenditure: "USD 16.95 Million",
        });
        const out = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        }); //Output the document using Data-URI
        saveAs(out, 'successStory.docx');
      })
  };
  return (
    <div className='m-auto'>
      <h1 className='text-center text-xl font-bold'>Export Bulk Documents Project Into ?</h1>
      <div className='flex flex-row justify-center pt-10 gap-x-16 max-[1024px]:gap-x-2'>
        <div className='text-center font-bold underline cursor-pointer'>
          <img src={pdfIcon} className='max-h-48'></img>
          Download as a PDF
        </div>
        <div className='text-center font-bold underline cursor-pointer' onClick={generateDocument}>
          <img src={docIcon} className='max-h-48'></img>
          Download as a DOC
        </div>
      </div>
    </div>
  )
}

export default ExportDocx;
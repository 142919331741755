import React, {useEffect, useState, useContext, createContext} from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from '../../../navigation/navbar';
import SideBar from '../../../navigation/sidebar';
import AddStatus from '../../../components/templates/form/datamaster/status/status';
import DataStatusTable from '../../../components/templates/table/datamaster/statusTable';

const AddStatusPage =() => {
    const [menu, setMenu] = useState("")
    const [sidebar, setSidebar] = useState([])
    return(
        <div className='flex bg-gray-200'>
                <div>
                    <SideBar/>
                </div>
                <div className='w-full'>
                    <Navbar/>
                    <div className='m-8 p-4 bg-white'>
                        <AddStatus/>
                        <div className='mt-4'>
                            <DataStatusTable/>
                        </div>
                    </div>    
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                    </div>
            </div>
        </div>
    )
}

export default AddStatusPage;
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import loginImages from '../assets/images/login.png'
import logoBappenas from '../assets/images/bappenasLogo.png'
import { myApi } from '../config/service/api'
import LoaderButton from '../components/atoms/button/loaderButton'
// const url = process.env.BACKEND_URL

const ResetPassword = () => {
  const { id, token } = useParams()
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const sendNewPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    axios({
      method: "post",
      url: `${myApi.URLPROD}/users/resetPassword/${id}/${token}`,
      data: {
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword
      }
    }).then(async (res) => {
      alert(res.data)
      console.log(res.data)
      setIsLoading(false)
      navigate('/login')
    }).catch((err) => {
      alert(err)
      setIsLoading(false)
      navigate('/login')
    })
  }

  useEffect(() => {
  }, [])

  return (
    <section className='bg-gray-50 min-h-screen flex items-center justify-center'>
      <div className='bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl'>
        <div className='w-1/2 flex '>
          <img
            className='rounded-l-2xl'
            src={loginImages}
            alt="images" />
        </div>
        <div className='sm:w-1/2 px-16 p-2'>
          <img
            className='rounded-2xl'
            src={logoBappenas}
            alt="images" />
          <h2 className=' text-2xl text-center mt-10'>Reset Password </h2>
          <form>
            <input
              onChange={(e) => setNewPassword(e.target.value)}
              className=' p-4 mt-8 w-full'
              id='newPassword'
              name='newPassword'
              value={newPassword}
              type='password'
              placeholder='New Password'
              required />
            <input
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className=' p-4 mt-4 w-full'
              id='confirmNewPassword'
              name='confirmNewPassword'
              value={confirmNewPassword}
              type='password'
              placeholder='Confirm New Password'
              required />
            <div className='flex justify-center mt-4'>
              {!isLoading
                ? <button
                  className='bg-blue-700 rounded-md text-white p-2 px-8 hover:bg-blue-500'
                  type='submit'
                  onClick={sendNewPassword}
                >
                  Submit
                </button>
                :
                <LoaderButton type='button' buttonColor='blue' textColor='white' />}
            </div>
          </form>

        </div>
      </div>
    </section>
  )
}

export default ResetPassword;
import React, {useContext, useEffect, useState} from 'react';
import Input1 from '../../atoms/input/Input1';
import Button1 from '../../../../src/components/atoms/button/button1'
import TextArea1 from '../../atoms/input/textarea1';
import { DataContext } from '../../../config/context';

const TechnicalSpecification = (props) => {
  const [counter, setCounter] = useState(0);
  const [projectStatus, setProjectStatus] = useState('');
  const [OBC, setOBC] = useState('');
  const [FBC, setFBC] = useState('');
  const [isSpecification, setIsSpecification] = useState(true);
  const [specification, setSpecification] = useState('');
  const [subSpecification, setSubSpecification] = useState('');
  const [capacity, setCapacity] = useState('');
  const[contextTableData, setContextTableData] = useContext(DataContext)
  const initialTableData = [
      [
          { value: '' },
          { value: '' },
          { value: '' },
      ],
      [
          { value: '' },
          { value: '' },
          { value: '' },
      ]
  ];

  const [tablesData, setTablesData] = useState(contextTableData.technicalSpecification?contextTableData.technicalSpecification:[initialTableData]);

  const addTable = () => {
      setTablesData([...tablesData, initialTableData]);
      setContextTableData(e => ({
        ...e, technicalSpecification: tablesData
    }))
  };

  const removeTable = (tableIndex) => {
      const updatedData = [...tablesData];
      updatedData.splice(tableIndex, 1);
      setTablesData(updatedData);
      setContextTableData(e => ({
        ...e, technicalSpecification: updatedData
    }))
    // console.log("tablesData",tablesData)
  };

  const addRow = (tableIndex) => {
    const updatedData = [...tablesData];
    const newRow = Array(updatedData[tableIndex][0].length).fill('').map(() => ({ value: '' }));
    updatedData[tableIndex] = [...updatedData[tableIndex], newRow];
    setTablesData(updatedData);
    setContextTableData(e => ({
      ...e, technicalSpecification: updatedData
  }))
};

  const addColumn = (tableIndex) => {
      const updatedData = [...tablesData];
      updatedData[tableIndex] = updatedData[tableIndex].map(row => [...row, { value: '' }]);
      setTablesData(updatedData);
      setContextTableData(e => ({
        ...e, technicalSpecification: updatedData
    }))
  };

  const handleTableChange = (tableIndex, rowIndex, cellIndex, newValue) => {
      const updatedData = [...tablesData];
      updatedData[tableIndex][rowIndex][cellIndex].value = newValue;
      setTablesData(updatedData);
      setContextTableData(e => ({
        ...e, technicalSpecification: updatedData
    }))
  };
  useEffect(()=>{
    console.log("tablesData",tablesData)
  })

    return ( 
      <div className="container mx-auto p-4">
          <div className="sm:col-span-2 mx-2 mb-5">
              <label htmlFor="documentProjectStatus" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
              </label>
              <div className="mt-2">
                  <TextArea1 
                  value={contextTableData.specDescription}
                  onChangeText={(val) =>{
                      const data = val.target.value
                      setContextTableData(e => ({
                          ...e, specDescription: data
                      }))
                  }}
                  placeholder={"Description Technical Specification"}></TextArea1>
              </div>
          </div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-4" onClick={addTable}>Add Table</button>
          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-4" onClick={()=>{console.log(contextTableData.technicalSpecification)}}>Submit</button> */}
          {tablesData.map((table, tableIndex) => (
              <div key={tableIndex} className="mb-8">
                  <h2 className="text-xl font-bold mb-2">Table {tableIndex + 1}</h2>
                  <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-2" onClick={() => addRow(tableIndex)}>Add Row</button>
                  <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded m-2" onClick={() => addColumn(tableIndex)}>Add Column</button>
                  <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => removeTable(tableIndex)}>Remove Table</button>
                  <table className="border-collapse border border-gray-600 w-full">
                      <tbody>
                          {table.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                  {row.map((cell, cellIndex) => (
                                      <td key={cellIndex} className="border border-gray-600 p-2">
                                          <input
                                              type="text"
                                              placeholder='type'
                                              value={cell.value}
                                              onChange={(e) => handleTableChange(tableIndex, rowIndex, cellIndex, e.target.value)}
                                              className="w-full"
                                          />
                                      </td>
                                  ))}
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
          ))}
      </div>
    )
}

export default TechnicalSpecification;
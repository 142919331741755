import React from 'react'; 

const LoaderButton = (props) => {
    return (
        <button
        class={`bg-${props.buttonColor}-500 rounded-md text-${props.textColor} p-2 px-8`}
        type={props.type}
        disabled
        >
        <div
            role="status"
            class="inline-block h-3 w-3 mr-2 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        >
            <span
            class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >
            Loading...
            </span>
        </div>
        Loading
        </button>
    );
}
export default LoaderButton
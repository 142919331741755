import React, {useEffect, useState} from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Input1 from '../../atoms/input/Input1';
import Button1 from '../../atoms/button/button1';
import Dropdown1 from '../../atoms/dropdown/dropdown1';
import TextArea1 from '../../atoms/input/textarea1';

const IndicativeProjectScheduleForm = (props) => {
  const [projectStatus, setProjectStatus] = useState('');
  const [OBC, setOBC] = useState('');
  const [FBC, setFBC] = useState('');
  const [preQualification, setPreQualification] = useState('');
  const [requestForProposal, setRequestForProposal] = useState('');
  const [aggrementSigning, setAggrementSigning] = useState('');
  const [bidAward, setBidAward] = useState('');
  const [financialClose, setFinancialClose] = useState('');
  const [construction, setConstruction] = useState('');

    return ( 
        <div className='m-auto'>
            <form>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                    <div className="
                        mt-10 grid grid-cols-1 sm:grid-cols-1 gap-4"
                        >
                        {props.data.map((item)=>{
                            if(item.input == "text") var component = <Input1 name={item.name} value={item.name} onChangeText={(val) => item.onchange(val.target.value)} typeInput="text" placeholder={item.placeholder} isDisabled={item.isDisabled}></Input1>
                            else if (item.input == "textarea") var component = <TextArea1 name={item.name} value={item.name} onChangeText={(val) => item.onchange(val.target.value)} placeholder={item.placeholder} isDisabled={item.isDisabled}></TextArea1>
                            else if (item.input == "dropdown") var component = <Dropdown1 options={item.listArray} onChange={(val)=>{item.onchange(val.target.value)}} value={item.name} isDisabled={item.isDisabled}/>
                            return <div className={item.class}>
                                <label htmlFor="documentProjectStatus" className="block text-sm font-medium leading-6 text-gray-900">
                                    {item.placeholder}
                                </label>
                                <div className="mt-2">
                                    {component}
                                </div>
                            </div>
                        })}
                    </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default IndicativeProjectScheduleForm;
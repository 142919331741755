import React, {useEffect, useState} from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CompareForm = () => {
    
    return ( 
        <div className='m-auto'>
            <form>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                    {/* <div className='flex flex-col'>
                        <select>
                            <option>2012</option>
                        </select>
                    </div> */}
                    {/* <div className="
                        mt-10 grid-flow-col grid grid-rows-7 gap-4 
                        sm:grid-rows-7 sm:grid-flow-col"
                        > */}
                    <div className="
                        mt-10 grid grid-row-1 gap-4
                        sm:grid-row-1"
                        >
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                                Project Name
                            </label>
                            <div className="mt-2">
                                <input
                                id="projectName"
                                name="projectName"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Project Name'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                                Location
                            </label>
                            <div className="mt-2">
                                <select
                                id="projectName"
                                name="projectName"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                >
                                    <option disabled>Location</option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                                Government Contracting Agency
                            </label>
                            <div className="mt-2">
                                <input
                                id="projectName"
                                name="projectName"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Government Contracting Agency'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                                Type of PPP
                            </label>
                            <div className="mt-2">
                                <select
                                id="projectName"
                                name="projectName"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                >
                                    <option disabled>Type of PPP</option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                                Return of Investment
                            </label>
                            <div className="mt-2">
                                <select
                                id="projectName"
                                name="projectName"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                >
                                    <option>Return of Investment</option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                                Sub-sector
                            </label>
                            <div className="mt-2">
                                <input
                                id="projectName"
                                name="projectName"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Project Name'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                Description
                            </label>
                            <div className="mt-2">
                                <input
                                id="description"
                                name="description"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Description'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="estimatedProjectValueCap" className="block text-sm font-medium leading-6 text-gray-900">
                               (Capital Expenditure USD)
                            </label>
                            <div className="mt-2">
                                <input
                                id="estimatedProjectValueCap"
                                name="estimatedProjectValueCap"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Estimated Project Value (Capital Expenditure USD)'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="estimatedProjectValueOps" className="block text-sm font-medium leading-6 text-gray-900">
                            (Operational Expenditure USD)
                            </label>
                            <div className="mt-2">
                                <input
                                id="estimatedProjectValueOps"
                                name="estimatedProjectValueOps"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Estimated Project Value (Operational Expenditure USD)'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="equityIrr" className="block text-sm font-medium leading-6 text-gray-900">
                                Equity IRR
                            </label>
                            <div className="mt-2">
                                <input
                                id="equityIrr"
                                name="equityIrr"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Equity IRR'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="firr" className="block text-sm font-medium leading-6 text-gray-900">
                                FIRR
                            </label>
                            <div className="mt-2">
                                <input
                                id="firr"
                                name="firr"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='FIRR'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="npv" className="block text-sm font-medium leading-6 text-gray-900">
                                NPV
                            </label>
                            <div className="mt-2">
                                <input
                                id="npv"
                                name="npv"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='NPV'
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-1 mx-2">
                            <label htmlFor="estimatedConcessionPeriod" className="block text-sm font-medium leading-6 text-gray-900">
                                Estimated Concession Period
                            </label>
                            <div className="mt-2">
                                <input
                                id="estimatedConcessionPeriod"
                                name="estimatedConcessionPeriod"
                                type="text"
                                className="
                                    py-4
                                    block w-full rounded-md 
                                    border-0 py-1.5 text-gray-900 shadow-sm 
                                    ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 placeholder: px-4
                                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                                    sm:text-sm sm:leading-6"
                                placeholder='Estimated Concession Period'
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-center gap-x-6">
                    <button
                        type="submit"
                        // className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        className='mx-1 cursor-pointer transition-all bg-blue-500 text-white px-20 py-2 rounded-lg
                        border-blue-600
                        border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                        active:border-b-[2px] active:brightness-90 active:translate-y-[2px]'
                        >
                    Submit
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CompareForm;
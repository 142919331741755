import React from 'react'; 

const TextArea1 = (props) => {
    return (
        
        <textarea
            id={props.name}
            name={props.name}
            type={props.typeInput}
            value={props.value}
            onChange={props.onChangeText}
            className="
                py-4
                block w-full rounded-md 
                border-0 py-1.5 text-gray-900 shadow-sm 
                ring-1 ring-inset ring-gray-300 
                placeholder:text-gray-400 placeholder: px-4
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                sm:text-sm sm:leading-6"
            placeholder={props.placeholder}
            disabled={props.isDisabled}
            rows={6}
            />
   
    );
}
export default TextArea1


import logo from './logo.svg';
import './App.css';
import { useState, useEffect, createContext } from 'react';
// import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Routes, Route, Switch, Navigate, useLocation, Link } from 'react-router-dom';
import { DataProvider } from './config/context';
import axios from "axios";
import { myApi } from '../src/config/service/api';

import Login from './pages/login';
import DataManagementPage from './pages/dataManagement';
import CompareDataPage from './pages/compareData';
import AddProjectDigestPage from './pages/addProject';
import AddIndicativeProjectSchedule from './pages/addIndicativeProjectSchedule';
import AddIndicativeProjectStructure from './pages/addIndicativeProjectStructure';
import ExportProjectPage from './pages/exportProject';
import DataMaster from './pages/datamaster/datamaster';
import AddStatusPage from './pages/datamaster/status/addstatus';
import AddGcamPage from './pages/datamaster/gcam/addGcam';
import AddYearPage from './pages/datamaster/year/addyear';
import AddTypeOfPPPPage from './pages/datamaster/typeOfPPP/addPPP'
import AddProjectReadyToOffer from './pages/documentByStatus/readyToOffer'
import AddProjectAlreadyTendered from './pages/documentByStatus/alreadyTendered';
import AddProjectUnderPreparation from './pages/documentByStatus/underPreparation';
import SuccessStory from './pages/documentByStatus/successStory';
import DetailProjectReadyToOffer from './pages/detailDocument/detailReadyToOffer';
import DetailProjectUnderPreparation from './pages/detailDocument/detailUnderPreparation';
import DetailAlreadyTendered from './pages/detailDocument/detailAlreadyTendered';
import DetailSuccessStory from './pages/detailDocument/detailSuccessStory';
import DetailForm from './pages/detailDocument/detailForm';
import UserManagementPage from './pages/usermanagement/userManagement';
import UserDetailPage from './pages/usermanagement/userDetail';
import UserChangePassword from './pages/usermanagement/userChangePassword';
import ResetPassword from './pages/resetPassword';
import ForgotPassword from './pages/forgotPassword';

function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route
            path='/'
            element={
              <PrivateRoute>
                <DataManagementPage />
              </PrivateRoute>
            } />
          <Route
            path='/compareData'
            element={
              <PrivateRoute>
                <CompareDataPage />
              </PrivateRoute>
            } />
          <Route
            path='/addProject'
            element={
              <PrivateRoute>
                <AddProjectDigestPage />
              </PrivateRoute>
            } />
          <Route
            path='/indicative'
            element={
              <PrivateRoute>
                <AddIndicativeProjectSchedule />
              </PrivateRoute>
            } />
          <Route path='/projectPicture' element={<AddIndicativeProjectStructure />} />
          <Route
            path='/exportProject'
            element={
              <PrivateRoute>
                <ExportProjectPage />
              </PrivateRoute>
            } />
          <Route
            path='/dataMaster'
            element={
              <PrivateRoute>
                <DataMaster />
              </PrivateRoute>
            } />
          <Route
            path='/addStatus'
            element={
              <PrivateRoute>
                <AddStatusPage />
              </PrivateRoute>
            } />
          <Route
            path='/addYear'
            element={
              <PrivateRoute>
                <AddYearPage />
              </PrivateRoute>
            } />
          <Route
            path='/addTypeOfPPP'
            element={
              <PrivateRoute>
                <AddTypeOfPPPPage />
              </PrivateRoute>
            } />
          <Route
            path='/addGcam'
            element={
              <PrivateRoute>
                <AddGcamPage />
              </PrivateRoute>
            } />
          <Route
            path='/readyToOffer'
            element={
              <PrivateRoute>
                {/* <AddProjectReadyToOffer/> */}
                <DetailForm typeForm='readyToOffer' />
              </PrivateRoute>
            } />
          <Route
            path='/readyToOffer/:id'
            element={
              <PrivateRoute>
                {/* <AddProjectReadyToOffer/> */}
                <DetailForm typeForm='readyToOffer' />
              </PrivateRoute>
            } />
          <Route
            path='/underPreparation'
            element={
              <PrivateRoute>
                {/* <AddProjectUnderPreparation/> */}
                <DetailForm typeForm='underPreparation' />
              </PrivateRoute>
            } />
          <Route
            path='/underPreparation/:id'
            element={
              <PrivateRoute>
                {/* <AddProjectUnderPreparation/> */}
                <DetailForm typeForm='underPreparation' />
              </PrivateRoute>
            } />
          <Route
            path='/alreadyTendered'
            element={
              <PrivateRoute>
                {/* <AddProjectAlreadyTendered/> */}
                <DetailForm typeForm='alreadyTendered' />
              </PrivateRoute>
            } />
          <Route
            path='/alreadyTendered/:id'
            element={
              <PrivateRoute>
                {/* <AddProjectAlreadyTendered/> */}
                <DetailForm typeForm='alreadyTendered' />
              </PrivateRoute>
            } />
          <Route
            path='/successStory'
            element={
              <PrivateRoute>
                {/* <SuccessStory/> */}
                <DetailForm typeForm='successStory' />
              </PrivateRoute>
            } />
          <Route
            path='/successStory/:id'
            element={
              <PrivateRoute>
                {/* <SuccessStory/> */}
                <DetailForm typeForm='successStory' />
              </PrivateRoute>
            } />
          <Route
            path='/agreementSigning'
            element={
              <PrivateRoute>
                {/* <agreementSigning/> */}
                <DetailForm typeForm='agreementSigning' />
              </PrivateRoute>
            } />
          <Route
            path='/agreementSigning/:id'
            element={
              <PrivateRoute>
                {/* <agreementSigning/> */}
                <DetailForm typeForm='agreementSigning' />
              </PrivateRoute>
            } />
          <Route
            path='/underConstruction'
            element={
              <PrivateRoute>
                {/* <underConstruction/> */}
                <DetailForm typeForm='underConstruction' />
              </PrivateRoute>
            } />
          <Route
            path='/underConstruction/:id'
            element={
              <PrivateRoute>
                {/* <underConstruction/> */}
                <DetailForm typeForm='underConstruction' />
              </PrivateRoute>
            } />
          <Route
            path='/underOperation'
            element={
              <PrivateRoute>
                {/* <underOperation/> */}
                <DetailForm typeForm='underOperation' />
              </PrivateRoute>
            } />
          <Route
            path='/underOperation/:id'
            element={
              <PrivateRoute>
                {/* <underOperation/> */}
                <DetailForm typeForm='underOperation' />
              </PrivateRoute>
            } />
          <Route
            path='/underProcurement'
            element={
              <PrivateRoute>
                {/* <underProcurement/> */}
                <DetailForm typeForm='underProcurement' />
              </PrivateRoute>
            } />
          <Route
            path='/underProcurement/:id'
            element={
              <PrivateRoute>
                {/* <underProcurement/> */}
                <DetailForm typeForm='underProcurement' />
              </PrivateRoute>
            } />
          <Route
            path='/readyForTransaction'
            element={
              <PrivateRoute>
                {/* <readyForTransaction/> */}
                <DetailForm typeForm='readyForTransaction' />
              </PrivateRoute>
            } />
          <Route
            path='/readyForTransaction/:id'
            element={
              <PrivateRoute>
                {/* <readyForTransaction/> */}
                <DetailForm typeForm='readyForTransaction' />
              </PrivateRoute>
            } />
          <Route
            path='/user'
            element={
              <PrivateRoute>
                <UserManagementPage />
              </PrivateRoute>
            } />
          <Route
            path='/createuser'
            element={
              <PrivateRoute>
                <UserDetailPage />
              </PrivateRoute>
            } />
          <Route
            path='/userdetail/:mode/:id'
            element={
              <PrivateRoute>
                <UserDetailPage />
              </PrivateRoute>
            } />
          <Route
            path='/changepassword'
            element={
              <PrivateRoute>
                <UserChangePassword />
              </PrivateRoute>
            } />

          <Route path='/settings' element={<DataManagementPage />} />

          <Route path='/settings' element={<DataManagementPage />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/resetPassword/:id/:token' element={<ResetPassword />} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

function PrivateRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  const location = useLocation();
  const refreshToken = async () => {
    let token = await localStorage.getItem("userToken");
    await axios.get(`${myApi.URLPROD}/users/refresh/${token}`)
    .then(async (res) =>{
      // console.log(`${JSON.stringify(res.data.message)} <<<<<<< Ini respon refresh token`)
      if(res.data.message === "Token Expired"){
        setLoading(false);console.log(`${JSON.stringify(res.data.message)}`)
        localStorage.removeItem("userToken");
        localStorage.removeItem("userRoles");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userName");
        setAuth(false);
      }else{
        setLoading(false);
        console.log(`${JSON.stringify(res.data)}`)
        await localStorage.setItem("userToken", res.data.refreshToken);
        // let refresh = await localStorage.getItem("userToken");
        // console.log(refresh+'<<<< refreshment')
        setAuth(true);
        setLoading(false);
      }
    })
  };
  // console.log(auth);
  useEffect(() => {
    refreshToken();
  }, []);
  return loading ? (
    <div class="flex justify-center items-center">
      <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  ) : !auth ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    children
  );
}

export default App;

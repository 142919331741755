import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import loginImages from '../assets/images/login.png'
import logoBappenas from '../assets/images/bappenasLogo.png'
import { myApi } from '../config/service/api'
// const url = process.env.BACKEND_URL

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(false)
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const Auth = async (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: myApi.URLPROD + "/users/login",
      data: {
        userEmail: email,
        userPassword: password,
      },
    })
      .then(async (response) => {
        // console.log(response.data);
        if (response.data == 'Invalid Credentials') {
          setMsg('Email or Password Invalid')
        } else {
          setMsg('')
          localStorage.setItem("userName", response.data.userName);
          localStorage.setItem("userRoles", response.data.userRoles);
          localStorage.setItem("userToken", response.data.userToken);
          history("/");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error") {
          console.log("Network Error")
          // swal({
          //   title: "Trouble On Connection",
          //   text: "Please check your connection",
          //   icon: "warning",
          //   button: "OK",
          // });
        }
        if (err.response) {
          setMsg(err.response.data.msg);
        }
      });
  };

  const cekStatusToken = async () => {
    let token = await localStorage.getItem("refreshtoken");
    if (!token) {
      setToken(false)
    } else {
      setToken(true)
    }
  }

  useEffect(() => {
    cekStatusToken()
  }, [])

  return (
    <section className='bg-gray-50 min-h-screen flex items-center justify-center'>
      <div className='bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl'>
        <div className='w-1/2 flex '>
          <img
            className='rounded-l-2xl'
            src={loginImages}
            alt="images" />
        </div>
        <div className='sm:w-1/2 px-16 p-2'>
          <img
            className='rounded-2xl'
            src={logoBappenas}
            alt="images" />
          <h2 className=' text-2xl text-center mt-10'>Login </h2>
          <form className='flex flex-col gap-4'>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className='p-2 mt-8'
              id='email'
              name='email'
              value={email}
              type='text'
              placeholder='Username'
              required />
            <input
              onChange={(e) => setPassword(e.target.value)}
              className='p-2 mt-2'
              id='password'
              name='password'
              value={password}
              type='password'
              placeholder='Password'
              required />
            <div className='text-xs text-red-500 italic'>
              {msg}
            </div>
            <div className='flex items-center justify-between mt-2'>
              <button
                className='bg-blue-700 rounded-md text-white p-2 px-8 hover:bg-blue-500'
                type='submit'
                onClick={Auth}>
                Login
              </button>
              <NavLink
                to='/forgotPassword'>
                Forgot Password ?
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Login;
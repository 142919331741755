import React, { useEffect, useState } from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { SquarePen, Trash2 } from 'lucide-react'
import Swal from 'sweetalert2'
import { myApi } from '../../../../config/service/api';

const DataTypeOfPPPTable = () => {
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(5)
    const lastDataIndex = currentPage * dataPerPage
    const firstDataIndex = lastDataIndex - dataPerPage
    const currentData = data.slice(firstDataIndex, lastDataIndex)

    const getDataTypeOfPPP = () => {
        axios.get(myApi.URLPROD + "/typeofppp/listTypeOfPPPAll")
            .then(async (response) => {
                // console.log(response.data)
                setData(response.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleDelete = async (id) => {
        Swal.fire({
            title: "Are you sure to delete this data?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${myApi.URLPROD}/typeofppp/deleteTypeofPPP/${id}`)
                    .then(async (response) => {
                        // console.log(response.data)
                        getDataTypeOfPPP()
                    }).catch((err) => {
                        console.log(err)
                    })
                Swal.fire({
                    title: "Deleted!",
                    text: "Your data has been deleted.",
                    icon: "success"
                });
            }
        })
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }
    function tableHeader(data) {
        // const header = Object.keys(data[0]);
        // return header.map((key, index) => <th className={`p-3 text-sm font-semibold tracking-wide text-left`} key={index}>{titleCase(key)}</th>)
        return (
            <tr className='table-row'>
                {/* <th className={`p-3 text-sm font-semibold tracking-wide text-left`}>No</th> */}
                <th className={`p-3 text-sm font-semibold tracking-wide text-left`}>Status</th>
                <th className='p-3 text-sm font-semibold tracking-wide text-center'>Action</th>
            </tr>
        )
    }
    function tableContent(data) {
        const keys = Object.keys(data[0]);
        console.log("data", data)
        const table = data.map((row, index) => {
            return (
                <tr className={`table-row ${index % 2 == 0 ? 'bg-gray-200' : 'bg-white'}`} key={index}>
                    {/* <td className={`p-3 text-sm text-gray-700 text-left`}>{index+1}</td> */}
                    {keys.map((key, index) => index > 0 ? <td className={`p-3 text-sm text-gray-700`} key={index}>{row[key]}</td> : <></>)}
                    <td className='flex flex-row content-center p-3 justify-center'>
                        <button
                            onClick={() => handleDelete(row._id)}
                            value={index}
                            className="mx-1 cursor-pointer transition-all bg-red-500 text-white px-5 py-1 rounded-lg
                            border-red-600
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                            <div><Trash2 /></div>
                        </button>
                    </td>
                </tr>
            )
        });
        return table;
    }

    function tablePagination(data) {
        let pages = []
        for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
            pages.push(i)
        }
        return (
            <div className="pagination mt-2 flex justify-center">
                {currentPage > 1
                    ? <a className="text-black float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer" onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</a>
                    : <a className="text-black float-left px-2 py-1 transition duration-300 bg-red disabled" ></a>}
                {pages.map((page, index) => {
                    return <button className={`${currentPage == page ? 'bg-gray-700 text-white' : 'text-black'} float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer`} onClick={() => setCurrentPage(page)}>{page}</button>
                })}
                {currentPage < pages.length
                    ? <a className="text-black float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer" onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</a>
                    : <a className="text-black float-left px-2 py-1 transition duration-300 bg-red disabled" ></a>}
            </div>
        )
    }

    useEffect(() => {
        getDataTypeOfPPP()
    }, [])

    return (
        <div>
            <h2 className='text-2xl font-bold pb-4'>Data Management</h2>
            {data.length > 0
                ? <div>
                    <div className='overflow-auto rounded-lg shadow'>
                        <table className="table w-full">
                            <thead className='table-header-group bg-gray-50 border-b-2 border-gray-200'>
                                {tableHeader()}
                                {/* <tr className='table-row'>
                                {tableHeader(currentData)}
                                <th className='p-3 text-sm font-semibold tracking-wide text-left'>Action</th>
                            </tr> */}
                            </thead>
                            <tbody className='table-row-group'>
                                {tableContent(currentData)}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {tablePagination(data)}
                    </div>
                </div>
                : <div className='text-gray-400 text-center p-20'>Empty Data</div>
            }

        </div>
    )
}

export default DataTypeOfPPPTable
import React, {useEffect, useState, useContext, createContext} from 'react';
import axios from "axios";
import { DataContext } from '../config/context';
import Navbar from '../navigation/navbar';
import SideBar from '../navigation/sidebar';
import IndicativeProjectScheduleForm from '../components/templates/form/indicativeProjectSchedule';

const AddIndicativeProjectSchedule =() => {
    const [menu, setMenu] = useContext(DataContext)
    const [sidebar, setSidebar] = useState([])
    useEffect( () => {
    }, [])
    return(
        <div className='flex bg-gray-200'>
            <div>
                <SideBar/>
            </div>
            <div className='w-full'>
                <Navbar/>
                <div className='m-8 p-4 bg-white'>
                    <IndicativeProjectScheduleForm/>
                </div>
            </div>
        </div>
    )
}

export default AddIndicativeProjectSchedule;
import React, { useContext, useEffect, useState } from 'react';

import Button1 from '../../../../atoms/button/button1';
import Input1 from '../../../../atoms/input/Input1';
import axios from "axios";
import Swal from 'sweetalert2'
import { myApi } from '../../../../../config/service/api';
import { DataContext } from '../../../../../config/context';
const AddSubBab = () => {
    const [dataContext, setDataContext] = useContext(DataContext)
    const [Loading, setLoading] = useState(false);
    const [subBab, setSubBab] = useState("")
    const sendData = async (event) => {
        // console.log("abcd")
        Swal.fire({
            title: "Do you want to save the changes?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
        }).then(async (result) => {
            if (result.isConfirmed) {
                // setLoading(true)
                try {
                    await axios({
                        method: 'post',
                        url: `${myApi.URLPROD}/subBab/addSubBab`,
                        data: {
                            subBabName: subBab,
                        }
                    }).then((res) => {
                        console.log(res)
                        // if (res.subBab == 200) {
                        //     Swal.fire({
                        //         title: 'Saved',
                        //         text: 'Your work has been saved!',
                        //         icon: 'success',
                        //     confirmButtonText: 'OK',
                        //     })
                        // } else {
                        //     Swal.fire({
                        //         title: 'Failed',
                        //         text: "Changes are not saved",
                        //     icon: 'error',
                        //     confirmButtonText: 'OK',
                        // })
                        // }
                        // window.location.reload()
                        // console.log(res)
                    })
                } catch (error) {
                    Swal.fire({
                        title: 'Failed',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                }
                setLoading(false)
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
    }
    const updateData = async (event) => {
        Swal.fire({
            title: "Do you want to save the changes?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
        }).then(async (result) => {
            if (result.isConfirmed) {
                // setLoading(true)
                if (subBab != "") {
                    // console.log(dataContext.subBabMasterData)
                    try {
                        await axios({
                            method: 'put',
                            url: `${myApi.URLPROD}/subBab/updateSubBab/${dataContext.subBabMasterData.id}`,
                            data: {
                                subBabName: subBab,
                            }
                        }).then((res) => {
                            console.log(res)
                            if (res.status == 200) {
                                Swal.fire({
                                    title: 'Saved',
                                    text: res.data.message,
                                    icon: 'success',
                                    confirmButtonText: 'OK',
                                })
                            } else {
                                Swal.fire({
                                    title: 'Failed',
                                    text: "Changes are not saved",
                                    icon: 'error',
                                    confirmButtonText: 'OK',
                                })
                            }
                            window.location.reload()
                            // console.log(res)
                        })
                    } catch (error) {
                        Swal.fire({
                            title: 'Failed',
                            text: error,
                            icon: 'error',
                            confirmButtonText: 'OK',
                        })
                    }
                } else {
                    Swal.fire({
                        title: 'Please full fill sub bab form',
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                }
                setLoading(false)
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
    }
    return (
        <div className='m-auto'>
            <form>
                <div className="space-y-3">
                    <div className="border-b border-gray-900/10">
                        <div className="mt-10 gap-4"
                        >
                            <div className="sm:col-span-1 mx-2">
                                <label htmlFor="documentProjectsubBab" className="block text-sm font-medium leading-6 text-gray-900">
                                    subBab
                                </label>
                                <div className="mt-2">
                                    {dataContext.subBabMasterData.editable
                                        ? <Input1 name="subBab" value={subBab} onChangeText={(val) => setSubBab(val.target.value)} typeInput="text" placeholder={dataContext.subBabMasterData.value}></Input1>
                                        : <Input1 name="subBab" value={subBab} onChangeText={(val) => setSubBab(val.target.value)} typeInput="text" placeholder="subBab"></Input1>
                                    }
                                </div>
                                <div className="mt-2 mb-2">
                                    {dataContext.subBabMasterData.editable
                                        ? <Button1 text="Update" type="button" onPress={() => { updateData() }}></Button1>
                                        : <Button1 text="Add" type="button" onPress={() => { sendData() }}></Button1>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddSubBab;
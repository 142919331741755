import React, { useEffect, useState } from 'react'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import loginImages from '../assets/images/login.png'
import logoBappenas from '../assets/images/bappenasLogo.png'
import { myApi } from '../config/service/api'
import LoaderButton from '../components/atoms/button/loaderButton'
// const url = process.env.BACKEND_URL

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const sendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    axios({
      method: "post",
      url: `${myApi.URLPROD}/users/forgotPassword`,
      data: {
        userEmail: email
      }
    }).then(async (res) => {
      alert(res.data)
      console.log(res.data)
      setIsLoading(false)
      navigate('/login')
    }).catch((err) => {
      alert(err)
      setIsLoading(false)
      navigate('/login')
    })
  }

  useEffect(() => {
  }, [])

  return (
    <section className='bg-gray-50 min-h-screen flex items-center justify-center'>
      <div className='bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl'>
        <div className='w-1/2 flex '>
          <img
            className='rounded-l-2xl'
            src={loginImages}
            alt="images" />
        </div>
        <div className='sm:w-1/2 px-16 p-2'>
          <img
            className='rounded-2xl'
            src={logoBappenas}
            alt="images" />
          <h2 className=' text-2xl text-center mt-10'>Forgot Password</h2>
          <form>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className=' p-4 mt-8 w-full'
              id='email'
              name='email'
              value={email}
              type='text'
              placeholder='Email'
              required />
            <div className='flex justify-center mt-4'>
              {!isLoading
                ? <div className='flex flex-col text-center space-y-4'><button
                  className='bg-blue-700 rounded-md text-white p-2 px-8 hover:bg-blue-500'
                  type='submit'
                  onClick={sendEmail}
                >
                  Submit
                </button>
                  <NavLink
                    className={`text-red-500 font-bold`}
                    to='/login'>
                    Cancel
                  </NavLink></div>
                :
                <LoaderButton type='button' buttonColor='blue' textColor='white' />}
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ForgotPassword;
import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../../config/context';

const ContactInformation = (props) => {
    const [contextTableData, setContextTableData] = useContext(DataContext)
    const [contactInfo, setContactInfo] = useState(contextTableData.contactInformation ? contextTableData.contactInformation : [{ name: '', position: '', phone: '', email: '' }]);

    const handleInputChange = (index, fieldName, value) => {
        const updatedContactInfo = [...contactInfo];
        updatedContactInfo[index][fieldName] = value;
        setContactInfo(updatedContactInfo);
        setContextTableData(e => ({
            ...e, contactInformation: updatedContactInfo
        }))
    };

    const handleAddTable = () => {
        setContactInfo([...contactInfo, { name: '', position: '', phone: '', email: '' }]);
        setContextTableData(e => ({
            ...e, contactInformation: contactInfo
        }))
    };

    const handleRemoveTable = (index) => {
        const updatedContactInfo = [...contactInfo];
        updatedContactInfo.splice(index, 1);
        setContactInfo(updatedContactInfo);
        setContextTableData(e => ({
            ...e, contactInformation: updatedContactInfo
        }))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(contactInfo);
        // Send contactInfo to backend or perform other actions
    };

    return (
        <div className="container mx-auto p-4">
            <form onSubmit={handleSubmit}>
                <div className="space-y-8">
                    {contactInfo.map((item, index) => (
                        <div key={index} className="bg-white rounded-md shadow-md p-6">
                            <h2 className="text-lg font-semibold mb-4">Contact Information {index + 1}</h2>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="flex flex-col">
                                    <label htmlFor={`name-${index}`} className="text-sm text-gray-700">Name</label>
                                    <input name={`name-${index}`} value={item.name} onChange={(e) => handleInputChange(index, 'name', e.target.value)} type="text" placeholder="Name" className="border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md px-4 py-2 mt-1" />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`position-${index}`} className="text-sm text-gray-700">Position</label>
                                    <input name={`position-${index}`} value={item.position} onChange={(e) => handleInputChange(index, 'position', e.target.value)} type="text" placeholder="Position" className="border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md px-4 py-2 mt-1" />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`phone-${index}`} className="text-sm text-gray-700">Phone</label>
                                    <input name={`phone-${index}`} value={item.phone} onChange={(e) => handleInputChange(index, 'phone', e.target.value)} type="text" placeholder="Phone" className="border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md px-4 py-2 mt-1" />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`email-${index}`} className="text-sm text-gray-700">Email</label>
                                    <input name={`email-${index}`} value={item.email} onChange={(e) => handleInputChange(index, 'email', e.target.value)} type="email" placeholder="Email" className="border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md px-4 py-2 mt-1" />
                                </div>
                            </div>
                        </div>
                    ))}
                    <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" onClick={handleAddTable}>Add Contact Information</button>
                    <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" onClick={handleRemoveTable}>Remove</button>
                    {/* <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">Submit</button> */}
                </div>
            </form>
        </div>
    )
}

export default ContactInformation;
import { useContext, useState, createContext, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import logoBappenas from '../assets/images/bappenasLogo.png'
import { DataContext } from '../config/context';
import axios from "axios";
import { myApi } from '../config/service/api';
import Swal from 'sweetalert2';
import {
  ChevronFirst,
  ChevronLast,
  CircleGauge,
  GitCompareArrows,
  Grid2X2,
  Heart,
  Settings,
  Power
} from 'lucide-react'
export const SidebarContext = createContext()
const SubSidebarContext = createContext()

const SideBar = () => {
  const [isAdmin, setIsAdmin] = useState(false)
  const [subMenu, setSubMenu] = useState({
    addProject: false,
    settings: false,
  })
  const getRole = async () => {
    const token = await localStorage.getItem('userToken')
    await axios.get(`${myApi.URLPROD}/users/roleUser/${token}`)
      .then(async (response) => {
        const { userPermission } = response.data
        setIsAdmin(userPermission)
        // console.log(JSON.stringify(response.data)+'<<<< Permission User')
      }).catch((err) => {
        console.log(err)
        Swal.fire({
          title: err.message,
          icon: "warning"
        });
      })
  }
  useEffect(() => {
    getRole()
    // var dataRole = localStorage.getItem("userRoles")
    // if (dataRole == "admin") setIsAdmin(true)
  })
  return (
    <SubSidebarContext.Provider value={([subMenu, setSubMenu])}>
      <Sidebar>
        <SideBarItem icon={<CircleGauge size={20} />} text='Data Management' destination='/' />
        {/* <SideBarItem icon={<GitCompareArrows size={20}/>} text='Compare Data' destination='/compareData'/> */}
        <SideBarItem icon={<Grid2X2 size={20} />} text='Add Project' parentMenu='Add Project' />
        {subMenu.addProject
          ? <div>
            <SideBarItem text='Ready To Offer' destination='/readyToOffer' submenu />
            <SideBarItem text='Under Preparation' destination='/underPreparation' submenu />
            <SideBarItem text='Already Tendered' destination='/alreadyTendered' submenu />
            <SideBarItem text='Success Story' destination='/successStory' submenu />
            <SideBarItem text='Agreement Signing' destination='/agreementSigning' submenu />
            <SideBarItem text='Under Construction' destination='/underConstruction' submenu />
            <SideBarItem text='Under Operation' destination='/underOperation' submenu />
            <SideBarItem text='Under Procurement' destination='/underProcurement' submenu />
            <SideBarItem text='Ready For Transaction' destination='/readyForTransaction' submenu />
          </div>
          : <></>
        }
        <hr className='my-3' />
        <SideBarItem icon={<Settings size={20} />} text='Settings' parentMenu='Settings' />
        {subMenu.settings
          ? <div>
            {isAdmin ? <SideBarItem text='User Management' destination='/user' submenu /> : ""}
            <SideBarItem text='Data Master' destination='/dataMaster' submenu />
          </div>
          : <></>
        }
        <SideBarItem icon={<Power size={20} />} text='Logout' logout>
        </SideBarItem>
      </Sidebar>
    </SubSidebarContext.Provider>
  )
}

function Sidebar({ children }) {
  const [expanded, setExpanded] = useState(SidebarContext)
  return (
    <aside className="h-screen">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div className={`p-4 pb-2 flex justify-between items-center ${expanded ? '' : 'flex-col'}`}>
          <img
            src={logoBappenas}
            className={`overflow-hidden transition-all ${expanded ? 'w-48' : 'w-12'
              }`}
            alt=''
          />
          <button
            onClick={() => setExpanded(curr => !curr)}
            className='p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100'>
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>
        <div className={`overflow-hidden transition all text-2xl text-center text-bold text-blue-500 ${expanded ? '' : 'w-0'}`}>PPP Books</div>
        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3">{children}</ul>
        </SidebarContext.Provider>
      </nav>
    </aside>
  )
}

function SideBarItem({ icon, text, active, alert, destination, logout, submenu, parentMenu }) {
  const { expanded } = useContext(SidebarContext)
  const [subMenu, setSubMenu] = useContext(SubSidebarContext)
  const [dataContext, setDataContext] = useContext(DataContext)
  return (
    submenu
      ? <NavLink
        to={destination}
        reloadDocument
        onClick={() => {
          if (logout) {
            Logout()
          } else if (parentMenu == 'Add Project') {
            setSubMenu(e => ({
              ...e, addProject: !e.addProject
            }))
            setDataContext(e => ({
              ...e, addProject: true,
            }))
          } else if (parentMenu == 'Settings') {
            setSubMenu(e => ({
              ...e, settings: !e.settings
            }))
          }
        }}
        className={`
          relative flex items-center py-2 px-3 h-12
          font-medium rounded-md cursor-pointer
          transition-colors group
          ${active
            ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
            : "hover:bg-indigo-50 text-gray-600"
          }
      `}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all ${!submenu
            ? expanded ? "w-52 ml-3" : "w-0"
            : expanded ? "w-40 ml-10" : "w-0"
            }`}
        >
          {text}
        </span>
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"
              }`}
          />
        )}

        {!expanded && (
          <div
            className={`
            absolute left-full rounded-md px-2 py-1 ml-6 w-max
            bg-indigo-100 text-indigo-800 text-sm
            invisible opacity-20 -translate-x-3 transition-all
            group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
        `}
          >
            {text}
          </div>
        )}
      </NavLink>
      : <NavLink
        to={destination}
        onClick={() => {
          if (logout) {
            Logout()
          } else if (parentMenu == 'Add Project') {
            setSubMenu(e => ({
              ...e, addProject: !e.addProject
            }))
          } else if (parentMenu == 'Settings') {
            setSubMenu(e => ({
              ...e, settings: !e.settings
            }))
          }
        }}
        className={`
            relative flex items-center py-2 px-3 h-12
            font-medium rounded-md cursor-pointer
            transition-colors group
            ${active
            ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
            : "hover:bg-indigo-50 text-gray-600"
          }
        `}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all ${!submenu
            ? expanded ? "w-52 ml-3" : "w-0"
            : expanded ? "w-40 ml-10" : "w-0"
            }`}
        >
          {text}
        </span>
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"
              }`}
          />
        )}

        {!expanded && (
          <div
            className={`
              absolute left-full rounded-md px-2 py-1 ml-6 w-max
              bg-indigo-100 text-indigo-800 text-sm
              invisible opacity-20 -translate-x-3 transition-all
              group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
          `}
          >
            {text}
          </div>
        )}
      </NavLink>
  )
}

function Logout() {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userRoles");
  localStorage.removeItem("userEmail");
  window.location.reload()
}

export default SideBar;
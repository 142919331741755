import React, {useEffect, useState} from 'react';
import Input1 from '../../atoms/input/Input1';
import Button1 from '../../atoms/button/button1'
import TextArea1 from '../../atoms/input/textarea1';
const ProjectCostStructureNew = (props) => {
  const [counter, setCounter] = useState(0);
  const [opex, setOpex] = useState('');
  const [capex, setCapex] = useState('');
  const [firr, setFirr] = useState('');
  const [eirr, setEirr] = useState('');
  const [OBC, setOBC] = useState('');
  const [FBC, setFBC] = useState('');
  const [isCostItem, setIsCostItem] = useState(true);
  const [costItem, setCostItem] = useState('');
  const [subSpecification, setSubSpecification] = useState('');
  const [costValue, setCostValue] = useState('');
  let arraySpecification = [
    {specification: "uaa", typeSpecification: "specification", capacity: "234"},
    {specification: "abcd", typeSpecification: "subSpecification", capacity: "234"},
  ]

  function handleClick(id) {
    console.log("Button clicked with id:", id);
    const newArrayCost = [...props.data.costItem]; // Create a copy of the array
    newArrayCost.splice(id, 1); // Remove the object at the specified index
    props.data.setCostItem(newArrayCost); // Update the state with the modified array
  }

  function Table(data) {
    let count = 0
    let count2 = 0
    return (
      <div className="overflow-x-auto mt-5">
                  <div className="sm:col-span-1 mx-2 mb-5">
                                <label htmlFor="documentProjectStatus" className="block text-sm font-medium leading-6 text-gray-900">
                                  {data.desc.placeholder}
                                </label>
                                <div className="mt-2">
                                    <TextArea1 name={data.desc.name} value={data.desc.name} onChangeText={(val) => data.desc.onchange(val.target.value)} isDisabled={props.isDisabled}></TextArea1>
                                </div>
                            </div>
        <div>
        </div>
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Estimated Project Value
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
              {/* <tr>
                <td className="px-6 py-4 whitespace-nowrap">
                    Capex
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <Input1 placeholder={data.capex.placeholder} name={data.capex.name} value={data.capex.name} onChangeText={(val)=>{data.capex.onchange(val.target.value)}} isDisabled={props.isDisabled}/>
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">
                    Opex
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                    <Input1 placeholder={data.opex.placeholder} name="opex" value={data.opex.name} onChangeText={(val)=>{data.opex.onchange(val.target.value)}} isDisabled={props.isDisabled}/>
                </td>
              </tr> */}
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">
                  FIRR
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <Input1 placeholder={data.firr.placeholder} name="firr" value={data.firr.name} onChangeText={(val)=>{data.firr.onchange(val.target.value)}} isDisabled={props.isDisabled}/>
                </td>
              </tr>  
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">
                  EIRR
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <Input1 placeholder={data.eirr.placeholder} name="eirr" value={data.eirr.name} onChangeText={(val)=>{data.eirr.onchange(val.target.value)}} isDisabled={props.isDisabled}/>
                </td>
              </tr>  
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">
                  NPV
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <Input1 placeholder={data.npv.placeholder} name={data.npv.name} value={data.npv.name} onChangeText={(val)=>{data.npv.onchange(val.target.value)}} isDisabled={props.isDisabled}/>
                </td>
              </tr>  
          </tbody>
        </table>
      </div>
    );
  }

    return ( 
        <div className='m-auto'>
            <form>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10">
                    <div className="flex justify-center pb-2">  
                                    {Table(props.data)}
                                </div> 
                    </div>
                                       
                </div>
            </form>
        </div>
    )
}

export default ProjectCostStructureNew;
import React, {useEffect, useState} from 'react';
import Input1 from '../../atoms/input/Input1';
import Button1 from '../../atoms/button/button1'

const ProjectCostStructure = (props) => {
  const [counter, setCounter] = useState(0);
  const [projectStatus, setProjectStatus] = useState('');
  const [OBC, setOBC] = useState('');
  const [FBC, setFBC] = useState('');
  const [isCostItem, setIsCostItem] = useState(true);
  const [costItem, setCostItem] = useState('');
  const [subSpecification, setSubSpecification] = useState('');
  const [costValue, setCostValue] = useState('');
  let arraySpecification = [
    {specification: "uaa", typeSpecification: "specification", capacity: "234"},
    {specification: "abcd", typeSpecification: "subSpecification", capacity: "234"},
  ]

  function handleClick(id) {
    console.log("Button clicked with id:", id);
    const newArrayCost = [...props.data.costItem]; // Create a copy of the array
    newArrayCost.splice(id, 1); // Remove the object at the specified index
    props.data.setCostItem(newArrayCost); // Update the state with the modified array
  }

  function Table(arr) {
    let count = 0
    let count2 = 0
    return (
      <div className="overflow-x-auto">
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                No
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cost Item
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cost Value
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {arr.map((val) => {
                // console.log(val.typeSpecification === "specification")
                count2++
                if(val.typeCostItem === "costItem"){
                    count++
                }
                return <tr>
                <td className="px-6 py-4 whitespace-nowrap">
                    {val.typeCostItem === "costItem"?count:""}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                    {val.costItem}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                    {val.costValue}
                </td>
              </tr>
                
            })}
            
          </tbody>
        </table>
      </div>
    );
  }

    return ( 
        <div className='m-auto'>
            <form>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10">
                        <div className="
                            mt-10 grid-flow-col grid grid-rows-5 gap-4 
                            sm:grid-rows-5 sm:grid-flow-col"
                            >
                                <div className="sm:col-span-2 mx-2">
                                    <label htmlFor="documentProjectStatus" className="block text-sm font-medium leading-6 text-gray-900">
                                        Add Cost Item <Button1 type="button" text={isCostItem?"CostItem":"SubCostItem"} onPress={()=>{setIsCostItem(!isCostItem)}}></Button1>
                                    </label>
                                    <div className="mt-2">
                                        <Input1 name="specification" value={costItem} onChangeText={(val) => setCostItem(val.target.value)} typeInput="text" placeholder="Cost Item"></Input1>
                                    </div>
                                </div>
                                <div className="sm:col-span-2 mx-2">
                                    <label htmlFor="documentProjectStatus" className="block text-sm font-medium leading-6 text-gray-900">
                                        Add Cost Value
                                    </label>
                                    <div className="mt-2">
                                        <Input1 name="addCostValue" value={costValue} onChangeText={(val) => setCostValue(val.target.value)} typeInput="text" placeholder="Add Cost Value"></Input1>
                                    </div>
                                </div>
                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <Button1 type="button" text="Add" onPress={()=>{
                                        let data = {costItem: costItem, typeCostItem: isCostItem?"costItem":"costValue", costValue: costValue}
                                        const newArrayCost = [...props.data.costItem, data];
                                        props.data.setCostItem(newArrayCost)
                                        setCostItem("")
                                        setCostValue("")
                                        console.log("arraySpec",props.data.costItem)
                                    }}>
                                    </Button1>
                                    <Button1 type="button" text="Reset" onPress={()=>{
                                        const newArrayCost = [];
                                        props.data.setCostItem(newArrayCost)
                                        setCostItem("")
                                        setCostValue("")
                                        console.log("arraySpec",props.data.costItem)
                                    }}>
                                    </Button1>
                                </div>  
                        </div>
                    </div>
                                <div className="flex justify-center pb-2">  
                                    {Table(props.data.costItem)}
                                </div>        
                </div>
            </form>
        </div>
    )
}

export default ProjectCostStructure;
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Input1 from '../../atoms/input/Input1';
import TextArea1 from '../../atoms/input/textarea1';
import Button1 from '../../atoms/button/button1';
import Swal from 'sweetalert2'
import { myApi } from '../../../config/service/api';
import Dropdown1 from '../../atoms/dropdown/dropdown1';
import Checkbox1 from '../../atoms/checkbox/checkbox';
const ProjectForm = (props) => {
  const [loading, setLoading] = useState('');
  const [arrayStatus, setArrayStatus] = useState([]);
  const [arrayRoi, setArrayRoi] = useState([]);
  const [arrayStatusProject, setArrayStatusProject] = useState([]);
  const [arrayGcam, setArrayGcam] = useState([]);
  const [arraySector, setArraySector] = useState([]);
  const [arraySubsector, setArraySubsector] = useState([]);
  const resetForm = (arr) => {
    arr.forEach(element => {
      element("")
    });
  }
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  const getDataStatus = async () => {
    await axios.get(`${myApi.URLPROD}/statuses/listStatusAll`, {}).then((res) => {
      if (res.status == 200) {
        if (res.data.length != 0) {
          let option = [{ value: "", label: "Choose" }]
          res.data.map((val) => {
            option.push({ value: val._id, label: val.statusName })
          })
          setArrayStatus(option)
          // console.log(arrayStatus)
        }
      } else {
        console.log("something wrong")
      }
      // console.log(res)
    }).catch((err) => {
      console.log(err)
      Swal.fire({
          title: err.message,
          icon: "warning"
      });
  })
  }


  useEffect(() => {
    console.log('useEffect-on ');
    getDataStatus()
  }, []);
  return (
    <div className='m-auto'>
      <form>
        <div className="space-y-14">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 sm:grid-cols-1 gap-4"
            >

              {
                props.data.map((item, index) => {
                  if (item.input == "text") var component = <Input1 name={item.name} value={item.name} onChangeText={(val) => item.onchange(val.target.value)} typeInput="text" placeholder={item.placeholder} isDisabled={item.isDisabled}></Input1>
                  else if (item.input == "textarea") var component = <TextArea1 name={item.name} value={item.name} onChangeText={(val) => item.onchange(val.target.value)} placeholder={item.placeholder} isDisabled={item.isDisabled}></TextArea1>
                  else if (item.input == "dropdown") var component = <Dropdown1 options={item.listArray} onChange={(val) => { item.onchange(val.target.value) }} value={item.name} isDisabled={item.isDisabled} />
                  else if (item.input == "checkbox") var component = <Checkbox1 label={item.placeholder} setDataChecked={item.onchange} dataChecked={item.value} name={item.name} isDisabled={item.isDisabled} />
                  return <div className="sm:col-span-1" style={{ marginBottom: item.input === "textarea" ? "1rem" : "0rem" }}>
                    <label htmlFor={item.name} key={index} className="block text-sm font-medium leading-6 text-gray-900">
                      {item.placeholder}
                    </label>
                    <div className="mt-2" >
                      {component}
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>

      </form>
    </div>
  )
}

export default ProjectForm;
import React, { useEffect, useState, useContext, createContext } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import { myApi } from '../../../../config/service/api'
import Dropdown1 from '../../../atoms/dropdown/dropdown1';
import { useNavigate, useParams } from 'react-router-dom';
import Input1 from '../../../atoms/input/Input1'


const DetailUser = () => {
    const navigate = useNavigate()
    const { mode, id } = useParams()
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [userName, setUsername] = useState("")
    const [userRole, setUserRole] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [visibleContent, setVisibleContent] = useState("")
    const arrayRoles = [
        { value: "", label: "Choose" },
        { value: "admin", label: 'Admin' },
        { value: "user", label: 'User' },
    ];
    const getDataById = async () => {
        await axios.get(myApi.URLPROD + '/users/userById/' + id)
            .then(async (response) => {
                console.log(response.data)
                setUsername(response.data[0].userName)
                setUserEmail(response.data[0].userEmail)
                setUserRole(response.data[0].userRoles)
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleResetPassword = async () => {
        Swal.fire({
            title: "Are you sure to reset password?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, reset it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.post(myApi.URLPROD + '/users/updatePwd/' + id, { "userPassword": "1111" })
                    .then(async (response) => {
                        console.log(response.data)
                        Swal.fire({
                            title: "Success!",
                            text: "User password has been reset.",
                            icon: "success"
                        });
                    }).catch((err) => {
                        console.log(err)
                    })
            }
        })

    }

    // -----------------------------
    const [arrayStatus, setArrayStatus] = useState([{ value: "", label: "Choose" }]);

    let dataSemesta = {
        userName: userName,
        userRoles: userRole,
        userEmail: userEmail,
    }

    // const arrayComponentContent = [
    //     { "name": projectYear, "onchange": setProjectYear, placeholder: "Year", input: "text", isDisabled: opportunity.disableEditDataManagement },
    //     { "name": governmentContractingAgency, "onchange": setGovernmentContractingAgency, placeholder: "Government Contracting Agency", input: "dropdown", listArray: arrayGcam, isDisabled: opportunity.disableEditDataManagement },
    // ]
    const arrayComponentTitle = [
        "Add Project",
        "Add Project Picture Profile",

    ]

    const handleUpdates = async (event) => {
        // console.log("abcd")
        Swal.fire({
            title: "Do you want to save the changes?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                try {
                    console.log("data semesta", dataSemesta)
                    await axios.post(`${myApi.URLPROD}/users/updateUser/${id}`, dataSemesta).then((res) => {
                        console.log(res)
                        getDataById()
                        Swal.fire({
                            title: 'Success',
                            text: "Data user has been updated!",
                            icon: 'success',
                            confirmButtonText: 'OK',
                        })
                    })
                } catch (error) {
                    Swal.fire({
                        title: 'Failed',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                }
                setLoading(false)
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
    }

    const handleCreate = async (event) => {
        // console.log("abcd")
        Swal.fire({
            title: "Do you want to save the changes?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                try {
                    console.log("data semesta", dataSemesta)
                    await axios.post(`${myApi.URLPROD}/users/createUser`, dataSemesta).then((res) => {
                        console.log(res)
                        setUsername("")
                        setUserEmail("")
                        setUserRole("")
                        Swal.fire({
                            title: 'Success',
                            text: "Data user has been created!",
                            icon: 'success',
                            confirmButtonText: 'OK',
                        })
                    })
                } catch (error) {
                    Swal.fire({
                        title: 'Failed',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                }
                setLoading(false)
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
    }

    useEffect(() => {
        getDataById()
        console.log(mode)
        if (mode === "edit") setIsEdit(false)
        if (mode === "detail") setIsEdit(true)
        // const urlParts = window.location.pathname.split('/');
        // const valueAfterBaseUrl = urlParts[2];
    }, [])
    return (
        <div className="container mx-auto p-4">
            <form onSubmit={() => { }}>
                <div className="space-y-8">
                    <div className="bg-white rounded-md shadow-md p-6">
                        <h2 className="text-lg font-semibold mb-4">Data User</h2>
                        <div className="grid grid-cols-1 gap-4">
                            <div className="flex flex-col">
                                <label htmlFor={"userName"} className="block text-sm font-medium leading-6 text-gray-900 mb-2">User Name</label>
                                <Input1 name="userName" type="text" value={userName} onChangeText={(val) => { setUsername(val.target.value) }} placeholder={"Username"} isDisabled={isEdit} />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor={"userRole"} className="block text-sm font-medium leading-6 text-gray-900 mb-2">Role</label>
                                <Dropdown1 options={arrayRoles} onChange={(val) => { setUserRole(val.target.value) }} value={userRole} isDisabled={isEdit} name={"userRole"} />
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor={"userEmail"} className="block text-sm font-medium leading-6 text-gray-900 mb-2">Email</label>
                                <Input1 name="userEmail" type="text" value={userEmail} onChangeText={(val) => { setUserEmail(val.target.value) }} placeholder={"Email"} isDisabled={isEdit} />
                            </div>
                        </div>
                    </div>
                    {
                        mode === "edit" ? <div><button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" onClick={handleUpdates}>Update</button><button type="button" className="bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded m-2" onClick={handleResetPassword}>Reset Password</button></div> : mode === "create" ?
                            <button type="button" className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" onClick={handleCreate}>Submit</button> : ""
                    }
                </div>
            </form>
        </div>
    )
}

export default DetailUser;
import React, { useState, createContext, useEffect  } from "react";

export const DataContext = createContext();

export const DataProvider = props => {
    const [data, setData] = useState(
        {  
            //Handle Add, Edit, Detail Form Project
            addProject:true,
            disableEditDataManagement:false,
            //Handle Form
            opportunityContext:"",
            businessEntityContext:"",
            environmentImpactContext:"",
            governmentSupportContext:"",
            landAcquisitionContext:"",
            projectPictureProfileContext:"",
            indicativeProjectStructureContext:"",
            projectPictureContext:"",
            descriptionProjectPicture:"",
            specDescription:"",
            technicalSpecification:[],
            contactInformation:[],
            //Handle Additional IPS Form for Success Story
            preFeasibilityStudy:"",
            feasibilityStudy:"",
            fsEvaluation:"",
            ammandementOfRequestOfProposal:"",
            operationIPSSuccessStory:"",
            //Handle Master Data
            subBabMasterData:{
                id:"",
                value:"",
                editable:false,
            },
        }
    )
    useEffect( () => {
    }, [])

    return(
        <DataContext.Provider value={[data, setData]}>
            {props.children}
        </DataContext.Provider>
    )
} 
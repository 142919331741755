import React from 'react'; 

const Button1 = (props) => {
    return (
        
        <button
            type={props.type}
            onClick={props.onPress}
            className='mx-1 cursor-pointer transition-all bg-green-500 
                text-white px-5 py-1 rounded-lg
                border-blue-600 border-b-[4px] 
                hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                active:border-b-[2px] active:brightness-90 active:translate-y-[2px]'>
            {props.text}
            </button>
   
    );
}
export default Button1


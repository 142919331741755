import React, {useContext, useEffect, useState} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Button1 from '../../atoms/button/button1';
import { DataContext } from '../../../config/context';

const AddBusinessEntityScopeOfWork = (props) => {
    const[opportunity, setOpportunity] = useContext(DataContext)
    return ( 
        <div className='p-3 pt-5'>
            <CKEditor
                editor={ Editor }
                data={opportunity.businessEntityContext}
                disabled={props.isDisabled}
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log( 'Editor is ready to use!', editor );
                }}
                onChange={ ( event, editor ) => {
                    const data = editor.getData()
                    console.log("isi tabel",data)
                    setOpportunity(e => ({
                        ...e, businessEntityContext: data
                    }))
                } }
                onBlur={ ( event, editor ) => {
                    // console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    // console.log( 'Focus.', editor );
                } }
            />
        </div>
    ) 
}

export default AddBusinessEntityScopeOfWork;
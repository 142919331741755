import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { ReceiptText, SquarePen, Trash2 } from 'lucide-react'
import Swal from 'sweetalert2'
import { myApi } from '../../../../config/service/api';
import { DataContext } from '../../../../config/context';

const url = myApi.URLPROD

const UserTable = () => {
    const [dataContext, setDataContext] = useContext(DataContext)
    const [dataTable, setDataTable] = useState([])
    const [selectDataPerPage, setSelectDataPerPage] = useState(5)
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(selectDataPerPage)
    let lastDataIndex = currentPage * dataPerPage
    let firstDataIndex = lastDataIndex - dataPerPage
    let currentData = dataTable.slice(firstDataIndex, lastDataIndex)
    const navigate = useNavigate()

    const getDataManagement = () => {
        axios.get(url + "/users/listUserAll")
            .then(async (response) => {
                // console.log(response.data)
                setDataTable(response.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleSelectDataPerPage = (event) => {
        setDataPerPage(event.target.value)
        console.log(event.target.value)
        lastDataIndex = currentPage * dataPerPage
        firstDataIndex = lastDataIndex - dataPerPage
        currentData = dataTable.slice(firstDataIndex, lastDataIndex)
        setCurrentPage(1)
    }

    const handleDetail = async (event) => {
        console.log("handleDetail")
        let index = parseInt(event.target.value)
        let detailItem = dataTable[index + ((currentPage - 1) * dataPerPage)]

        navigate(`/userdetail/detail/${detailItem._id}`)
    }

    const handleEdit = async (event) => {
        console.log("handleEdit")
        let index = parseInt(event.target.value)
        let detailItem = dataTable[index + ((currentPage - 1) * dataPerPage)]

        navigate(`/userdetail/edit/${detailItem._id}`)
    }

    const handleDelete = async (event) => {
        let index = parseInt(event.target.value)
        // console.log(event.nativeEvent.target.value)
        let deleteItem = dataTable[index + ((currentPage - 1) * dataPerPage)]
        Swal.fire({
            title: "Are you sure to delete this user?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${url}/users/deleteUser/${deleteItem._id}`)
                let newDocument = dataTable.filter((e) => { return e !== deleteItem })
                setDataTable(newDocument)
                Swal.fire({
                    title: "Deleted!",
                    text: "User has been deleted.",
                    icon: "success"
                });
            }
        })
    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }
    function tableHeader(data) {
        // const header = Object.keys(data[0]);
        // return header.map((key, index) => <th className={`p-3 text-sm font-semibold tracking-wide text-left`} key={index}>{titleCase(key)}</th>)
        return (
            <tr className='table-row'>
                {/* <th className={`p-3 text-sm font-semibold tracking-wide text-left`}>No</th> */}
                <th className={`p-3 text-sm font-semibold tracking-wide text-left`}>Username</th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-left`}>Role</th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-left`}>Email</th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>Action</th>
            </tr>
        )
    }
    function tableContent(data) {
        const keys = Object.keys(data[0]);
        const table = data.map((row, index) => {
            return (
                <tr className={`table-row ${index % 2 == 0 ? 'bg-gray-200' : 'bg-white'}`} key={index}>
                    {/* <td className={`p-3 text-sm text-gray-700 text-left`}>{index+1}</td> */}
                    {keys.map((key, index) => index > 0 ? <td className={`p-3 text-sm text-gray-700`} key={index}>{row[key]}</td> : <></>)}
                    <td className='flex flex-row content-center p-3 justify-center'>
                        <button
                            name='detail'
                            onClick={handleDetail}
                            value={index}
                            className="mx-1 cursor-pointer transition-all bg-blue-500 text-white px-5 py-1 rounded-lg
                            border-blue-600
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]" >
                            <ReceiptText pointerEvents="none" />
                        </button>
                        <button
                            name='edit'
                            onClick={handleEdit}
                            value={index}
                            className="mx-1 cursor-pointer transition-all bg-yellow-500 text-white px-5 py-1 rounded-lg
                            border-yellow-600
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                            <SquarePen pointerEvents="none" />
                        </button>
                        <div>
                        </div>
                        <button
                            name='delete'
                            type='submit'
                            onClick={handleDelete}
                            value={index}
                            className="mx-1 cursor-pointer transition-all bg-red-500 text-white px-5 py-1 rounded-lg
                            border-red-600
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                            <Trash2 pointerEvents="none" />
                        </button>
                    </td>
                </tr>
            )
        });
        return table;
    }

    function tablePagination(data) {
        let pages = []
        for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
            pages.push(i)
        }
        return (
            <div className="pagination mt-2 flex justify-center">
                {currentPage > 1
                    ? <a className="text-black float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer" onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</a>
                    : <a className="text-black float-left px-2 py-1 transition duration-300 bg-red disabled" ></a>}
                {pages.map((page, index) => {
                    return <button className={`${currentPage == page ? 'bg-gray-700 text-white' : 'text-black'} float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer`} onClick={() => setCurrentPage(page)}>{page}</button>
                })}
                {currentPage < pages.length
                    ? <a className="text-black float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer" onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</a>
                    : <a className="text-black float-left px-2 py-1 transition duration-300 bg-red disabled" ></a>}
            </div>
        )
    }

    useEffect(() => {
        getDataManagement()
    }, [])

    return (
        <div>
            <h2 className='text-2xl font-bold pb-4'>User Management</h2>
            <div className='flex flex-row'>
                <button
                    className="bg-green-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => navigate('/userdetail/create/-')}
                >Create User</button>

            </div>
            {dataTable.length > 0
                ? <div>
                    <div className='overflow-auto rounded-lg shadow'>
                        <table className="table w-full">

                            <thead className='table-header-group bg-gray-50 border-b-2 border-gray-200'>
                                {tableHeader()}
                                {/* <tr className='table-row'>
                                {tableHeader(currentData)}
                                <th className='p-3 text-sm font-semibold tracking-wide text-left'>Action</th>
                            </tr> */}
                            </thead>
                            <tbody className='table-row-group'>
                                {tableContent(currentData)}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex flex-row mt-2 text-sm'>
                        <div className=''>
                            Data Per Page
                        </div>
                        <div className='ml-2'>
                            <select id='setDataPerPage' className='border-2 rounded-sm' onChange={handleSelectDataPerPage}>
                                <option value='5' >5</option>
                                <option value='10' >10</option>
                                <option value='25' >25</option>
                                <option value='50' >50</option>
                                <option value='100' >100</option>
                            </select>
                        </div>
                        <div className='ml-4'>Total Data {dataTable.length}</div>
                    </div>
                    <div>
                        {tablePagination(dataTable)}
                    </div>
                </div>
                : <div className='text-gray-400 text-center p-20'>Empty Data</div>
            }

        </div>
    )
}

export default UserTable
import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { ReceiptText, SquarePen, Trash2, ArrowDownAz, ArrowUpZa, Filter, Divide, ArrowDownZa, ArrowDownAZ } from 'lucide-react'
import Swal from 'sweetalert2'
import { myApi } from '../../../config/service/api';
import { DataContext } from '../../../config/context';
import ExportToExcel from '../../organisms/exportExcel';
import Checkbox1 from '../../atoms/checkbox/checkbox';
import Modal1 from '../../molecules/modal/modal1';
import Dropdown1 from '../../atoms/dropdown/dropdown1';
// const XLSXColor = require('xlsx-color');
// const XLSX = require('xlsx');
import ExcelJS from 'exceljs';


const url = myApi.URLPROD
const urlDocs = myApi.URLEXPORTDOCS

const DataManagementTable = () => {
    const [dataContext, setDataContext] = useContext(DataContext)
    const [dataMaster, setDataMaster] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [dataExcel, setDataExcel] = useState([])
    const [selectDataPerPage, setSelectDataPerPage] = useState(100)
    const [currentPage, setCurrentPage] = useState(1)
    const [dataPerPage, setDataPerPage] = useState(selectDataPerPage)
    const [showModalExcel, setShowModalExcel] = useState(false);
    const [showModalWord, setShowModalWord] = useState(false);
    const [isExportStatus, setIsExportStatus] = useState(false);
    const [isExportSector, setIsExportSector] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [statusValue, setStatusValue] = useState("");
    const [yearValue, setYearValue] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [arrayProjectCategory, setArrayProjectCategory] = useState([{ value: "", label: "Choose" }]);
    const [arrayYear, setArrayYear] = useState([{ value: "", label: "Choose" }]);
    const [arrayStatus, setArrayStatus] = useState([{ value: "", label: "Choose" }]);
    const [projectCategory, setProjectCategory] = useState('');

    const [isLoading, setIsLoading] = useState(true)

    const [formData, setFormData] = useState({
        documentYear: false,
        documentProjectName: false,
        documentLocation: false,
        documentType: false,
        documentReturnOfInvesment: false,
        documentGovernmentContractingAgency: false,
        documentSector: false,
        documentSubSector: false,
        documentReturnOfInvesment: false,
        documentEstimatedProjectValue: false,
        documentestimatedProjectValueOps: false,
        documentEquityIRR: false,
        documentFIRR: false,
        documentNPV: false,
        documentEstimatedConcessionPeriod: false,
    });

    // Function to handle checkbox changes
    const handleCheckboxChange = (event) => {
        // const { name, checked } = event.target;
        // setFormData({ ...formData, [name]: checked });
        // console.log("formData", formData)
        const itemName = event.target.name;

        setCheckedItems((prevItems) => {
            if (prevItems.includes(itemName)) {
                return prevItems.filter((item) => item !== itemName);
            } else {
                console.log(checkedItems)
                return [...prevItems, itemName];
            }
        });
    };

    const handleSearchInputChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        // console.log("newSearchTerm", newSearchTerm)
        // Use newSearchTerm directly for filtering
        const filteredProjects = newSearchTerm !== ""
            ? dataMaster.filter(project =>
                project.documentProjectName.toLowerCase().includes(newSearchTerm.toLowerCase())
            )
            : dataMaster;

        // console.log("filteredProjects", filteredProjects);

        setDataTable(filteredProjects);
        const currentData = filteredProjects.slice(firstDataIndex, lastDataIndex);
        navigate('/');  // Assuming this is necessary to trigger a re-render or navigation

        // Log current data if needed
        console.log("currentData",

        );
    };

    const handleCheckboxChange2 = (event) => {
        // const { name, checked } = event.target;
        // setFormData({ ...formData, [name]: checked });
        // console.log("formData", formData)
        const itemName = event.target.name;
        console.log("itemName", itemName)
        setCheckedRows((prevItems) => {
            if (prevItems.includes(itemName)) {
                console.log(checkedRows)
                return prevItems.filter((item) => item !== itemName);
            } else {
                console.log(checkedRows)
                return [...prevItems, itemName];
            }
        });
    };

    const doExport = async () => {
        Swal.showLoading();

        checkedItems.push("documentManualEdit")
        console.log(checkedItems)
        await axios.post(`${myApi.URLPROD}/documents/export`, { fields: checkedItems }).then((res) => {
            if (res.status == 200) {
                let responseData = res.data
                console.log(res.data)
                exportToExcel(res.data, null, "dataExport")
            } else {
                console.log("something wrong")
            }
        })
        Swal.hideLoading();
        Swal.fire({
            timer: 100,
            showConfirmButton: false
        });

    }

    const exportToExcel = (data, headers2, fileName) => {
        // const ws = XLSX.utils.json_to_sheet(data, { header: headers });
        // const wb = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // XLSX.writeFile(wb, `${fileName}.xlsx`);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headers = Object.keys(data[0]).filter(header => header !== 'documentManualEdit'); // Exclude documentManualEdit field
        worksheet.addRow(headers);

        data.forEach(row => {
            const values = headers.map(header => row[header]);
            worksheet.addRow(values);
        });

        worksheet.eachRow((row, rowIndex) => {
            const rowData = data[rowIndex - 2]; // Adjust for header row
            console.log("row", rowData)
            if (rowData?.documentManualEdit) {
                console.log("redddd", rowData)
                row.eachCell(cell => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF0000' } // Red color
                    };
                });
            }
        });

        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'output.xlsx';
            a.click();
        });
    };

    let lastDataIndex = currentPage * dataPerPage
    let firstDataIndex = lastDataIndex - dataPerPage
    let currentData = dataTable.slice(firstDataIndex, lastDataIndex)
    const modalBodyExcel =
        <div className="grid">
            <Checkbox1 label={"Book Year"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentYear")} name={"documentYear"} />
            <Checkbox1 label={"Project Name"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentProjectName")} name={"documentProjectName"} />
            <Checkbox1 label={"Project Status"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentProjectStatus")} name={"documentProjectStatus"} />
            <Checkbox1 label={"Return of Invesment"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentReturnOfInvesment")} name={"documentReturnOfInvesment"} />
            <Checkbox1 label={"Government Contacting Agency"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentGovernmentContractingAgency")} name={"documentGovernmentContractingAgency"} />
            <Checkbox1 label={"Sector"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentSector")} name={"documentSector"} />
            <Checkbox1 label={"Subsector"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentSubSector")} name={"documentSubSector"} />
            <Checkbox1 label={"Estimated Project Value Cap"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentestimatedProjectValueCap")} name={"documentestimatedProjectValueCap"} />
            <Checkbox1 label={"Estimated Project Value Ops"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentestimatedProjectValueOps")} name={"documentestimatedProjectValueOps"} />
            <Checkbox1 label={"Equity IRR"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentEquityIRR")} name={"documentEquityIRR"} />
            <Checkbox1 label={"FIRR"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentFIRR")} name={"documentFIRR"} />
            <Checkbox1 label={"NPV"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentNPV")} name={"documentNPV"} />
            <Checkbox1 label={"Estimated Concession Period"} setDataChecked={handleCheckboxChange} dataChecked={checkedItems.includes("documentEstimatedConcessionPeriod")} name={"documentEstimatedConcessionPeriod"} />
        </div>

    const [activeButton, setActiveButton] = useState(null);

    const handleActiveButton = (buttonName) => {
        setActiveButton(buttonName);
    };
    const modalBodyWord =
        <div className="grid">
            <div className="flex justify-between m-5">
                <button
                    className={`text-sm border rounded-sm px-2 py-1 transition-all duration-300 ${activeButton === 'status' ? 'text-white bg-blue-500' : 'text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    type="button"
                    onClick={() => handleActiveButton('status')}
                >
                    By Status
                </button>
                <button
                    className={`text-sm border rounded-sm px-2 py-1 transition-all duration-300 ${activeButton === 'projectCategory' ? 'text-white bg-blue-500' : 'text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    type="button"
                    onClick={() => handleActiveButton('projectCategory')}
                >
                    By Project Category
                </button>
                <button
                    className={`text-sm border rounded-sm px-2 py-1 transition-all duration-300 ${activeButton === 'all' ? 'text-white bg-blue-500' : 'text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    type="button"
                    onClick={() => handleActiveButton('all')}
                >
                    Export All
                </button>
            </div>
            <div>
                <div className="space-y-14">
                    <div className="border-b border-gray-900/10 pb-6">
                        {activeButton === "status" ? <div className="ml-5 mr-5 grid grid-cols-1 sm:grid-cols-1 gap-4">
                            <label htmlFor={"year"} className="block text-sm font-medium text-gray-900">
                                {"Year"}
                            </label>
                            <Dropdown1 options={arrayYear} onChange={(val) => { setYearValue(val.target.value) }} value={yearValue} isDisabled={false} name={"year"} />
                            <label htmlFor={"status"} className="block text-sm font-medium text-gray-900">
                                {"Status"}
                            </label>
                            <Dropdown1 options={arrayStatus} onChange={(val) => { setStatusValue(val.target.value) }} value={statusValue} isDisabled={false} name={"status"} />
                            <label htmlFor={"projectCategory"} className="block text-sm font-medium text-gray-900">
                                {"Project Category"}
                            </label>
                            <Dropdown1 options={arrayProjectCategory} onChange={(val) => { setProjectCategory(val.target.value) }} value={projectCategory} isDisabled={false} name={"projectCategory"} />

                        </div> : activeButton === "projectCategory" ? <div className="ml-5 mr-5 grid grid-cols-1 sm:grid-cols-1 gap-4">

                            <label htmlFor={"year"} className="block text-sm font-medium text-gray-900">
                                {"Year"}
                            </label>
                            <Dropdown1 options={arrayYear} onChange={(val) => { setYearValue(val.target.value) }} value={yearValue} isDisabled={false} name={"year"} />
                            <label htmlFor={"projectCategory"} className="block text-sm font-medium text-gray-900">
                                {"Project Category"}
                            </label>
                            <Dropdown1 options={arrayProjectCategory} onChange={(val) => { setProjectCategory(val.target.value) }} value={projectCategory} isDisabled={false} name={"projectCategory"} />
                        </div> : activeButton === "all" ? <div className="ml-5 mr-5 grid grid-cols-1 sm:grid-cols-1 gap-4"><label htmlFor={"projectCategory"} className="block text-sm font-medium text-gray-900">

                            <label htmlFor={"year"} className="block text-sm font-medium text-gray-900">
                                {"Year"}
                            </label>
                            <Dropdown1 options={arrayYear} onChange={(val) => { setYearValue(val.target.value) }} value={yearValue} isDisabled={false} name={"year"} />
                        </label></div> : ""}
                    </div>
                </div>
            </div>
        </div >

    const navigate = useNavigate()
    const [filterTable, setFilterTable] = useState({
        initialYear: '',
        bookYear: '',
        projectStatus: '',
        sector: '',
        manualEdit: '',
    })
    const defaultSortMode = {
        documentInitialYear: false,
        documentProjectYear: false,
        documentSector: false,
        documentProjectName: false,
        documentLocation: false,
        documentProjectStatus: false
    }
    const [sortMode, setSortMode] = useState({ defaultSortMode })
    const [filterMode, setFilterMode] = useState(false)
    const [sortAZ, setSortAZ] = useState(false)
    const [sortZA, setSortZA] = useState(false)


    // Mengambil list all data project dr backend
    const getDataManagement = async () => {
        console.log("getDataManagement")
        setIsLoading(true)
        await axios.get(myApi.URLPROD + "/documents/listDocumentAll", { timeout: 10000 })
            .then(async (response) => {
                const allData = response.data
                setDataTable(allData.map
                    (({ _id, documentYear, documentSector, documentProjectName, documentLocation, documentProjectStatus, documentModifiedBy, documentModifiedAt }) => ({
                        _id, documentYear, documentSector, documentProjectName, documentLocation, documentProjectStatus, documentModifiedBy, documentModifiedAt
                    })))
                setDataMaster(allData.map
                    (({ _id, documentYear, documentSector, documentProjectName, documentLocation, documentProjectStatus, documentModifiedBy, documentModifiedAt }) => ({
                        _id, documentYear, documentSector, documentProjectName, documentLocation, documentProjectStatus, documentModifiedBy, documentModifiedAt
                    })))
                setDataExcel(allData.map
                    (({ documentProjectName, documentestimatedProjectValueCap, documentestimatedProjectValueOps, documentNPV }) => ({
                        ProjectName: documentProjectName,
                        Capex: documentestimatedProjectValueCap,
                        Opex: documentestimatedProjectValueOps,
                        NPV: documentNPV
                    })))
                console.log(dataTable)

            }).catch((err) => {
                // console.log(err)
                Swal.fire({
                    title: err.message,
                    icon: "warning",
                    confirmButtonText: "Reload"
                }).then((result) => {
                    if (result.isConfirmed) {
                        getDataManagement()
                    }
                });
            })
        setIsLoading(false)
    }

    const getDataProjectCategory = async () => {
        // setIsLoading(e => ({
        //     ...e, getDataProjectCategoryLoading: true
        // }))
        await axios.get(`${myApi.URLPROD}/projectCategory/listProjectCategoryAll`, {}).then((res) => {
            if (res.status == 200) {
                if (res.data.length != 0) {
                    let option = [{ value: "", label: "Choose" }]
                    res.data.map((val) => {
                        option.push({ value: val.projectCategoryName, label: val.projectCategoryName })
                    })
                    setArrayProjectCategory(option)
                    // console.log(arrayStatus)
                }
            } else {
                console.log("something wrong")
            }
            // setIsLoading(e => ({
            //     ...e, getDataProjectCategoryLoading: false
            // }))
            // console.log(res)
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                title: err.message,
                icon: "warning"
            });
            // setIsLoading(e => ({
            //     ...e, getDataProjectCategoryLoading: false
            // }))
        })
    }

    const getDataYear = async () => {
        // setIsLoading(e => ({
        //     ...e, getDataYearLoading: true
        // }))
        await axios.get(`${myApi.URLPROD}/documents/documentyear-distinct`, {}).then((res) => {
            if (res.status == 200) {
                if (res.data.length != 0) {
                    let option = [{ value: "", label: "Choose" }]
                    res.data.map((val) => {
                        option.push({ value: val, label: val })
                    })
                    setArrayYear(option)
                    // console.log(arrayStatus)
                }
            } else {
                console.log("something wrong")
            }
            // setIsLoading(e => ({
            //     ...e, getDataYearLoading: false
            // }))
            // console.log(res)
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                title: err.message,
                icon: "warning"
            });
            // setIsLoading(e => ({
            //     ...e, getDataYearLoading: false
            // }))
        })
    }

    const getDataStatusProject = async () => {
        // setIsLoading(e => ({
        //     ...e, getDataStatusLoading: true
        // }))
        await axios.get(`${myApi.URLPROD}/statuses/listStatusAll`, {}).then((res) => {
            if (res.status == 200) {
                if (res.data.length != 0) {
                    let option = [{ value: "", label: "Choose" }]
                    res.data.map((val) => {
                        option.push({ value: val._id, label: val.statusName })
                    })
                    setArrayStatus(option)
                }
            } else {
                console.log("something wrong")
            }
            // setIsLoading(e => ({
            //     ...e, getDataStatusLoading: false
            // }))
            // console.log(res)
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                title: err.message,
                icon: "warning"
            });
            // setIsLoading(e => ({
            //     ...e, getDataStatusLoading: false
            // }))
        })
    }

    // Mengambil list all data sector dr backend
    const handleSelectDataPerPage = (event) => {
        setDataPerPage(event.target.value)
        // console.log(event.target.value)
        lastDataIndex = currentPage * dataPerPage
        firstDataIndex = lastDataIndex - dataPerPage
        currentData = dataTable.slice(firstDataIndex, lastDataIndex)
        setCurrentPage(1)
    }

    const handleDetail = async (event) => {
        setDataContext(e => ({
            ...e,
            disableEditDataManagement: true,
        }))
        localStorage.setItem('disableEditDataManagement', true);

        let index = parseInt(event.target.value)
        // console.log(event.nativeEvent.target.value)
        let detailItem = dataTable[index + ((currentPage - 1) * dataPerPage)]
        // console.log(detailItem)
        if (detailItem.documentProjectStatus == 'Ready To Offer') {
            navigate(`/readyToOffer/${detailItem._id}`)
            // navigate(`/detailReadyToOffer/${detailItem._id}`)
            // console.log("ready to offer")
        } else if (detailItem.documentProjectStatus == 'Under Preparation') {
            navigate(`/underPreparation/${detailItem._id}`)
            // navigate(`/detailUnderPreparation/${detailItem._id}`)
        } else if (detailItem.documentProjectStatus == 'Already Tendered') {
            navigate(`/alreadyTendered/${detailItem._id}`)
            // navigate(`/detailAlreadyTendered/${detailItem._id}`)
            // console.log("Already Tendered")
        } else if (detailItem.documentProjectStatus == 'Success Story') {
            navigate(`/successStory/${detailItem._id}`)
            // navigate(`/detailSuccessStory/${detailItem._id}`)
            // console.log("Success Story")
        } else if (detailItem.documentProjectStatus == 'Agreement Signing') {
            navigate(`/agreementSigning/${detailItem._id}`)
            // navigate(`/detailSuccessStory/${detailItem._id}`)
            // console.log("Success Story")
        } else if (detailItem.documentProjectStatus == 'Under Operation') {
            navigate(`/underOperation/${detailItem._id}`)
            // navigate(`/detailSuccessStory/${detailItem._id}`)
            // console.log("Success Story")
        } else if (detailItem.documentProjectStatus == 'Under Construction') {
            navigate(`/underConstruction/${detailItem._id}`)
            // navigate(`/detailSuccessStory/${detailItem._id}`)
            // console.log("Success Story")
        } else if (detailItem.documentProjectStatus == 'Under Procurement') {
            navigate(`/underProcurement/${detailItem._id}`)
            // navigate(`/underProcurement/${detailItem._id}`)
            // console.log("Under Procurement")
        } else if (detailItem.documentProjectStatus == 'Ready For Transaction') {
            navigate(`/readyForTransaction/${detailItem._id}`)
            // navigate(`/underProcurement/${detailItem._id}`)
            // console.log("Under Procurement")
        }
    }

    const handleEdit = async (event) => {
        setDataContext(e => ({
            ...e,
            addProject: false,
            disableEditDataManagement: false,
        }))
        localStorage.setItem('disableEditDataManagement', false);
        let index = parseInt(event.target.value)
        // console.log(event.nativeEvent.target.value)
        let editItem = dataTable[index + ((currentPage - 1) * dataPerPage)]
        if (editItem.documentProjectStatus == 'Ready To Offer') {
            navigate(`/readyToOffer/${editItem._id}`)
            // navigate(`/detailReadyToOffer/${editItem._id}`)
            // console.log("ready to offer")
        } else if (editItem.documentProjectStatus == 'Under Preparation') {
            navigate(`/underPreparation/${editItem._id}`)
            // navigate(`/detailUnderPreparation/${editItem._id}`)
            // console.log("Under Preparation")
        } else if (editItem.documentProjectStatus == 'Already Tendered') {
            navigate(`/alreadyTendered/${editItem._id}`)
            // navigate(`/detailAlreadyTendered/${editItem._id}`)
            // console.log("Already Tendered")
        } else if (editItem.documentProjectStatus == 'Success Story') {
            navigate(`/successStory/${editItem._id}`)
            // navigate(`/detailSuccessStory/${editItem._id}`)
            // console.log("Success Story")
        } else if (editItem.documentProjectStatus == 'Agreement Signing') {
            navigate(`/agreementSigning/${editItem._id}`)
            // navigate(`/detailSuccessStory/${editItem._id}`)
            // console.log("Success Story")
        } else if (editItem.documentProjectStatus == 'Under Operation') {
            navigate(`/underOperation/${editItem._id}`)
            // navigate(`/detailSuccessStory/${editItem._id}`)
            // console.log("Success Story")
        } else if (editItem.documentProjectStatus == 'Under Construction') {
            navigate(`/underConstruction/${editItem._id}`)
            // navigate(`/detailSuccessStory/${editItem._id}`)
            // console.log("Success Story")
        } else if (editItem.documentProjectStatus == 'Under Procurement') {
            navigate(`/underProcurement/${editItem._id}`)
            // navigate(`/detailSuccessStory/${editItem._id}`)
            // console.log("Success Story")
        } else if (editItem.documentProjectStatus == 'Ready For Transaction') {
            navigate(`/readyForTransaction/${editItem._id}`)
            // navigate(`/detailSuccessStory/${editItem._id}`)
            // console.log("Success Story")
        }
    }

    const handleDelete = async (event) => {
        setIsLoading(true)
        let index = parseInt(event.target.value)
        // console.log(event.nativeEvent.target.value)
        let deleteItem = dataTable[index + ((currentPage - 1) * dataPerPage)]
        Swal.fire({
            title: "Are you sure to delete this project?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${myApi.URLPROD}/documents/deleteDocument/${deleteItem._id}`)
                let newDocument = dataTable.filter((e) => { return e !== deleteItem })
                setDataTable(newDocument)
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
            setIsLoading(false)
        })
    }

    const handleExportModal = async (type) => {
        Swal.showLoading();
        const headers = {
            'Content-Type': 'application/json',
        }
        let url
        if (type === "status") {
            if (statusValue == "" || statusValue == null) Swal.fire({
                title: 'Error!',
                text: 'Choose the value!',
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false
            })
            url = "/export/status/" + projectCategory + "/" + yearValue + "/" + statusValue
        } else if (type === "projectCategory") {
            if (projectCategory == "" || projectCategory == null) Swal.fire({
                title: 'Error!',
                text: 'Choose the value!',
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false
            })
            url = "/export/" + projectCategory + "/" + yearValue
        } else if (type === "all") url = "/export/" + yearValue
        else Swal.fire({
            title: 'Error!',
            text: 'Something Wrong, Please Contact Administrator!',
            icon: 'error',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false
        })
        console.log(urlDocs + url)
        axios.post(urlDocs + url, {}, { responseType: 'arraybuffer' })
            .then(async (response) => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'file.docx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                Swal.hideLoading();
                Swal.fire({
                    timer: 100,
                    showConfirmButton: false
                });
            }).catch((err) => {
                Swal.hideLoading();
                Swal.fire({
                    timer: 100,
                    showConfirmButton: false
                });
                console.log(err)
            })
    }

    const handleExport = async (id) => {
        Swal.showLoading();

        // console.log("the id is", id)
        let data = id
        // console.log("data send", data)
        const headers = {
            'Content-Type': 'application/json',
        }
        axios.post(urlDocs + "/export/id/", { "ids": data }, { responseType: 'arraybuffer' })
            .then(async (response) => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'file.docx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                Swal.hideLoading();
                Swal.fire({
                    timer: 100,
                    showConfirmButton: false
                });
            }).catch((err) => {
                Swal.hideLoading();
                Swal.fire({
                    timer: 100,
                    showConfirmButton: false
                });
                console.log(err)
            })


    }

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    function tableFilter(data) {
        const manualEdit = [true, false]
        // const manualEdit = [...new Set(data.map(item => item.documentManualEdit))];
        const initialYear = [...new Set(data.map(item => item.documentInitialYear))];
        const bookYear = [...new Set(data.map(item => item.documentYear))];
        const projectStatus = [...new Set(data.map(item => item.documentProjectStatus))];
        const sector = [...new Set(data.map(item => item.documentSector))];
        return (
            <div className='flex justify-between w-full'>
                <select
                    id="Manual Edit"
                    onChange={(val) => {
                        setFilterTable(e => ({
                            ...e, manualEdit: val.target.value
                        }))
                    }}
                    className="block w-full py-2 px-3 rounded-md 
                        border border-gray-300 bg-white shadow-sm text-sm
                        focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-2"
                    style={{ marginBottom: '1rem' }}>
                    <option disabled selected>Manual Edit</option>
                    {manualEdit.map(opt => <option value={opt}>{String(opt)}</option>)}
                    <option value={''}>-</option>
                </select>
                <select
                    id="initialYear"
                    onChange={(val) => {
                        setFilterTable(e => ({
                            ...e, initialYear: val.target.value
                        }))
                    }}
                    className="block w-full py-2 px-3 rounded-md 
                        border border-gray-300 bg-white shadow-sm text-sm
                        focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-2"
                    style={{ marginBottom: '1rem' }}>
                    <option disabled selected>Initial Year</option>
                    {bookYear.map(opt => <option value={opt}>{opt}</option>)}
                    <option value={''}>-</option>
                </select>
                <select
                    id="bookYear"
                    onChange={(val) => {
                        setFilterTable(e => ({
                            ...e, bookYear: val.target.value
                        }))
                    }}
                    className="block w-full py-2 px-3 rounded-md 
                        border border-gray-300 bg-white shadow-sm text-sm
                        focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-2"
                    style={{ marginBottom: '1rem' }}>
                    <option disabled selected>Book Year</option>
                    {bookYear.map(opt => <option value={opt}>{opt}</option>)}
                    <option value={''}>-</option>
                </select>
                <select
                    id="projectStatus"
                    onChange={(val) => {
                        setFilterTable(e => ({
                            ...e, projectStatus: val.target.value
                        }))
                    }}
                    className="block w-full py-2 px-3 rounded-md 
                        border border-gray-300 bg-white shadow-sm text-sm
                        focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-2"
                    style={{ marginBottom: '1rem' }}>
                    <option disabled selected>Project Status</option>
                    {projectStatus.map(opt => <option value={opt}>{opt}</option>)}
                    <option value={''}>-</option>
                </select>
                <select
                    id="sector"
                    onChange={(val) => {
                        setFilterTable(e => ({
                            ...e, sector: val.target.value
                        }))
                    }}
                    className="block w-full py-2 px-3 rounded-md 
                        border border-gray-300 bg-white shadow-sm text-sm
                        focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mr-2"
                    style={{ marginBottom: '1rem' }}>
                    <option disabled selected>Sector</option>
                    {sector.map(opt => <option value={opt}>{opt}</option>)}
                    <option value={''}>-</option>
                </select>
                <button
                    name='filter'
                    value={JSON.stringify(filterTable)}
                    onClick={handleFilter}
                    style={{ marginBottom: '1rem' }}
                    type='button'
                    className={`mx-1 cursor-pointer transition-all text-white px-5 py-1 rounded-lg bg-gray-400 border-gray-500
                        border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                        active:border-b-[2px] active:brightness-90 active:translate-y-[2px]`}>
                    <Filter pointerEvents="none" />
                </button>
            </div>

        )
    }

    function tableSearch() {

        return (
            <div className='flex justify-between w-full'>
                <input
                    type="text"
                    placeholder="Search by project name"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    className="block w-full py-2 px-3 rounded-md 
            border border-gray-300 bg-white shadow-sm text-sm
            focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    style={{ marginBottom: '1rem' }}
                />
            </div>

        )
    }





    const handleFilter = async (event) => {
        setIsLoading(true)
        // console.log(filterMode)
        // if (!filterMode) {
        setIsLoading(true)
        let data = JSON.parse(event.target.value)
        console.log(data)
        await axios.post(myApi.URLPROD + "/documents/filter", data)
            .then(async (response) => {
                const filteredData = response.data
                setDataTable(filteredData.map
                    (({ _id, documentYear, documentSector, documentProjectName, documentLocation, documentProjectStatus, documentModifiedBy, documentModifiedAt }) => ({
                        _id, documentYear, documentSector, documentProjectName, documentLocation, documentProjectStatus, documentModifiedBy, documentModifiedAt
                    })))
                currentData = dataTable.slice(firstDataIndex, lastDataIndex)
                setFilterMode(true)
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
                Swal.fire({
                    title: err.message,
                    icon: "warning"
                });
                setIsLoading(false)
            })
        // } else {
        //     setDataTable(dataMaster)
        //     setFilterMode(false)
        //     setIsLoading(false)
        // }
    }

    const sortTable = async (event) => {
        // console.log(event.target.value)
        let key = event.target.value
        let sortedData = dataMaster
        if (!sortMode[key]) {
            setSortMode(defaultSortMode)
        }
        setSortMode(e => ({
            ...e, [key]: true,
        }))
        if (!sortAZ && !sortZA) {
            setSortAZ(true)
            sortedData = dataTable.sort((a, b) => {
                if (a[key] < b[key]) { return -1; }
                if (a[key] > b[key]) { return 1; }
                return 0;
            })
        } else if (sortAZ && !sortZA) {
            setSortZA(true)
            sortedData = dataTable.sort((a, b) => {
                if (a[key] < b[key]) { return 1; }
                if (a[key] > b[key]) { return -1; }
                return 0;
            })
        } else {
            setSortAZ(false)
            setSortZA(false)
            setSortMode(defaultSortMode)
        }
        setDataTable(sortedData)
        currentData = dataTable.slice(firstDataIndex, lastDataIndex)
        navigate('/')
        // console.log(dataTable)
    }

    function tableHeader(data) {
        // const header = Object.keys(data[0]);
        // return header.map((key, index) => <th className={`p-3 text-sm font-semibold tracking-wide text-left`} key={index}>{titleCase(key)}</th>)
        return (
            <tr className='table-row'>
                {/* <th className={`p-3 text-sm font-semibold tracking-wide text-left`}>No</th> */}
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div></div>
                        <button
                            onClick={sortTable}
                            value='documentProjectYear'>

                        </button>
                    </div>
                </th>

                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div>Project Year</div>
                        <button
                            onClick={sortTable}
                            value='documentProjectYear'>
                            {sortMode.documentProjectYear ? sortAZ ? sortZA ? <ArrowDownZa pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />}
                        </button>
                    </div>
                </th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div>Sector</div>
                        <button
                            onClick={sortTable}
                            value='documentSector'>
                            {sortMode.documentSector ? sortAZ ? sortZA ? <ArrowDownZa pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />}
                        </button>
                    </div>
                </th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div>Project Name</div>
                        <button
                            onClick={sortTable}
                            value='documentProjectName'>
                            {sortMode.documentProjectName ? sortAZ ? sortZA ? <ArrowDownZa pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />}
                        </button>
                    </div>
                </th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div>Location</div>
                        <button
                            onClick={sortTable}
                            value='documentLocation'>
                            {sortMode.documentLocation ? sortAZ ? sortZA ? <ArrowDownZa pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />}
                        </button>
                    </div>
                </th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div>Status</div>
                        <button
                            onClick={sortTable}
                            value='documentProjectStatus'>
                            {sortMode.documentProjectStatus ? sortAZ ? sortZA ? <ArrowDownZa pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />}
                        </button>
                    </div>
                </th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div>Modified By</div>
                        <button
                            onClick={sortTable}
                            value='documentModifiedBy'>
                            {sortMode.documentModifiedBy ? sortAZ ? sortZA ? <ArrowDownZa pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />}
                        </button>
                    </div>
                </th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>
                    <div className='flex'>
                        <div>Modified At</div>
                        <button
                            onClick={sortTable}
                            value='documentModifiedAt'>
                            {sortMode.documentModifiedBy ? sortAZ ? sortZA ? <ArrowDownZa pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#1a1a1a' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />
                                : <ArrowDownAz pointerEvents="none" color='#d9d9d9' />}
                        </button>
                    </div>
                </th>
                <th className={`p-3 text-sm font-semibold tracking-wide text-center`}>Action</th>
            </tr>
        )
    }

    function tableContent(data) {
        // console.log("data", data)
        const keys = Object.keys(data[0]);
        const table = data.map((row, index) => {
            // console.log("row", row)
            return (
                <tr className={`table-row ${index % 2 == 0 ? 'bg-gray-200' : 'bg-white'}`} key={index}>
                    {/* <td className={`p-3 text-sm text-gray-700 text-left`}>{index+1}</td> */}
                    <td>
                        <div className='m-2'>
                            <Checkbox1 setDataChecked={handleCheckboxChange2} dataChecked={checkedRows.includes(row._id)} name={row._id} />
                        </div>
                    </td>
                    {keys.map((key, index) => index > 0 ? <td className={`p-3 text-sm text-gray-700`} key={index}>{row[key]}</td> : <></>)}
                    <td className='flex flex-row content-center p-3 justify-center'>
                        <button
                            name='detail'
                            onClick={handleDetail}
                            value={index}
                            className="mx-1 cursor-pointer transition-all bg-blue-500 text-white px-5 py-1 rounded-lg
                            border-blue-600
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]" >
                            <ReceiptText pointerEvents="none" />
                        </button>
                        <button
                            name='edit'
                            onClick={handleEdit}
                            value={index}
                            className="mx-1 cursor-pointer transition-all bg-yellow-500 text-white px-5 py-1 rounded-lg
                            border-yellow-600
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                            <SquarePen pointerEvents="none" />
                        </button>
                        <div>
                        </div>
                        <button
                            name='delete'
                            type='submit'
                            onClick={handleDelete}
                            value={index}
                            className="mx-1 cursor-pointer transition-all bg-red-500 text-white px-5 py-1 rounded-lg
                            border-red-600
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px] no">
                            <Trash2 pointerEvents="none" />
                        </button>
                    </td>
                </tr>
            )
        });
        return table;
    }

    function tablePagination(data) {
        let pages = []
        for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
            pages.push(i)
        }
        return (
            <div className="pagination mt-2 flex justify-center">
                {currentPage > 1
                    ? <a className="text-black float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer" onClick={() => setCurrentPage(currentPage - 1)}>&laquo;</a>
                    : <a className="text-black float-left px-2 py-1 transition duration-300 bg-red disabled" ></a>}
                {pages.map((page, index) => {
                    return <button className={`${currentPage == page ? 'bg-gray-700 text-white' : 'text-black'} float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer`} onClick={() => setCurrentPage(page)}>{page}</button>
                })}
                {currentPage < pages.length
                    ? <a className="text-black float-left px-2 py-1 transition duration-300 hover:bg-[#ddd] cursor-pointer" onClick={() => setCurrentPage(currentPage + 1)}>&raquo;</a>
                    : <a className="text-black float-left px-2 py-1 transition duration-300 bg-red disabled" ></a>}
            </div>
        )
    }

    useEffect(() => {
        getDataManagement()
        getDataProjectCategory()
        getDataYear()
        getDataStatusProject()
    }, [])

    return (
        <div>
            <h2 className='text-2xl font-bold pb-4'>Data Management</h2>

            <div className='flex flex-row'>
                <button
                    className="bg-green-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalExcel(true)}
                >Export Excel</button>
                <button
                    className={`${checkedRows.length == 0 ? 'bg-blue-500' : 'bg-blue-900'} text-white active:bg-black-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 ease-linear transition-all duration-150`}
                    type="button"
                    onClick={() => checkedRows.length == 0 ? setShowModalWord(true) : handleExport(checkedRows)}
                >Export Docs</button>
                {showModalExcel ? <Modal1 modalTitle="Choose Field to Export" setShowModal={setShowModalExcel} handleCheckboxChange={handleCheckboxChange} formData={formData} body={modalBodyExcel} onSubmit={doExport} /> : ""}
                {showModalWord ? <Modal1 modalTitle="Export Docs" setShowModal={setShowModalWord} formData={formData} body={modalBodyWord} onSubmit={() => { handleExportModal(activeButton) }} /> : ""}
            </div>
            <div className='flex flex-row'>
                {tableFilter(dataMaster)}
            </div>
            <div className='flex flex-row'>
                {tableSearch()}
            </div>
            {isLoading
                ? <div className="h-svh flex-col gap-4 w-full flex items-center justify-center">
                    <div className="w-28 h-28 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-400 rounded-full">
                        <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" class="animate-ping">
                        </svg>
                    </div>
                </div>
                : dataTable.length > 0
                    ? <>
                        {/* <h2 className='text-2xl font-bold pb-4'>Data Management</h2> */}

                        <div>
                            <div className='overflow-auto rounded-lg shadow'>
                                <table className="table w-full">

                                    <thead className='table-header-group bg-gray-50 border-b-2 border-gray-200'>
                                        {tableHeader()}
                                        {/* <tr className='table-row'>
                                {tableHeader(currentData)}
                                <th className='p-3 text-sm font-semibold tracking-wide text-left'>Action</th>
                            </tr> */}
                                    </thead>
                                    <tbody className='table-row-group'>
                                        {tableContent(currentData)}
                                    </tbody>
                                </table>
                            </div>
                            <div className='flex flex-row mt-2 text-sm'>
                                <div className=''>
                                    Data Per Page
                                </div>
                                <div className='ml-2'>
                                    <select id='setDataPerPage' className='border-2 rounded-sm' onChange={handleSelectDataPerPage}>
                                        <option value='5' >5</option>
                                        <option value='10' selected='selected'>10</option>
                                        <option value='25' >25</option>
                                        <option value='50' >50</option>
                                        <option value='100' >100</option>
                                    </select>
                                </div>
                                <div className='ml-4'>Total Data {dataTable.length}</div>
                            </div>
                            <div>
                                {tablePagination(dataTable)}
                            </div>
                        </div>
                    </>
                    : <div className='text-gray-400 text-center p-20'>Empty Data</div>}

        </div >
    )
}

export default DataManagementTable
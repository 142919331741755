import React, { useEffect, useState, useContext, createContext } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import { myApi } from '../../../../config/service/api'
import Dropdown1 from '../../../atoms/dropdown/dropdown1';
import { useNavigate, useParams } from 'react-router-dom';
import Input1 from '../../../atoms/input/Input1'


const ChangePasword = () => {
    const navigate = useNavigate()
    const { mode, id } = useParams()
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [userName, setUsername] = useState("")
    const [userRole, setUserRole] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPassword1, setUserPassword1] = useState("")
    const [userPassword2, setUserPassword2] = useState("")
    const [password2Error, setPassword2Error] = useState('');
    const [token, setToken] = useState('');


    const [visibleContent, setVisibleContent] = useState("")
    const arrayRoles = [
        { value: "", label: "Choose" },
        { value: "admin", label: 'Admin' },
        { value: "user", label: 'User' },
    ];

    let handleSubmit = async () => {
        let dataPassword = {
            "userPassword": userPassword1,
            "token": token
        }
        Swal.fire({
            title: "Are you sure ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sure",
            denyButtonText: `Cancel`
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                await axios.post(myApi.URLPROD + '/users/updatePwd', dataPassword)
                    .then(async (response) => {
                        console.log(response)
                        Swal.fire({
                            title: 'Success',
                            text: "Your password has been updated!",
                            icon: 'success',
                            confirmButtonText: 'OK',
                        })
                        setUserPassword2("")
                        setUserPassword1("")
                    }).catch((err) => {
                        Swal.fire({
                            title: 'Failed',
                            text: "Password change failed!",
                            icon: 'error',
                            confirmButtonText: 'OK',
                        })
                    })
                setLoading(false)
            } else if (result.isDenied) {
                Swal.fire("Password change failed!", "", "info");
            }
        });

    }

    const handlePassword2Change = (val) => {
        const value = val.target.value;
        setUserPassword2(value);

        if (value.length < 3) {
            setPassword2Error('Password must be at least 3 characters long.');
            setIsSubmit(false)
        } else if (value.length > 20) {
            setPassword2Error('Password must be less than 20 characters long.');
            setIsSubmit(false)
        } else if (value !== userPassword1) {
            setPassword2Error('Passwords do not match.');
            setIsSubmit(false)
        } else {
            setPassword2Error('');
            setIsSubmit(true)
        }
        // console.log("lewatr")
    };
    useEffect(() => {
        const token = localStorage.getItem('userToken');
        setToken(token)
    }, [])
    return (
        <div className="container mx-auto p-4">
            <form onSubmit={() => { }}>
                <div className="space-y-8">
                    <div className="bg-white rounded-md shadow-md p-6">
                        <h2 className="text-lg font-semibold mb-4">Change Password</h2>
                        <div className="grid grid-cols-1 gap-4">
                            <div className="flex flex-col">
                                <label htmlFor={"userName"} className="block text-sm font-medium leading-6 text-gray-900 mb-2">Password</label>
                                <Input1 name="password1" typeInput="password" value={userPassword1} onChangeText={(val) => { setUserPassword1(val.target.value) }} placeholder={"Password"} isDisabled={false} />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor={"userName"} className="block text-sm font-medium leading-6 text-gray-900 mb-2">Confirmation Password</label>
                                <Input1 name="password2" typeInput="password" value={userPassword2} onChangeText={handlePassword2Change} placeholder={"Confirmation Password"} isDisabled={false} />
                            </div>
                        </div>
                        {password2Error && (
                            <span className="text-red-500 text-sm">{password2Error}</span>
                        )}
                    </div>
                    <button type="button" className={isSubmit
                        ? "bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2"
                        : "bg-green-200 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2"
                    } onClick={isSubmit ? handleSubmit : () => { }}>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default ChangePasword;
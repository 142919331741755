
import React from 'react';

const Dropdown1 = ({ label, isDisabled, options, ...rest }) => {
  return (
    <div className="mb-4">
      <label htmlFor="select" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        id="select"
        className="
              mt-1
              pl-3 pr-10 py-4 
              block w-full rounded-md 
              border border-gray-300 
              bg-white
              shadow-sm 
              focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
              sm:text-sm"
        {...rest}
        disabled={isDisabled}
      >
        {options.map(option => (
          <option value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>

  );
}
export default Dropdown1

